import React from 'react';
import RecoverBateryDataTable from '../components/Table/RecoverBateryDataTable';

function RecoverBatery() {
  return (
    <div>
          <RecoverBateryDataTable/>
    </div>
  );
}

export default RecoverBatery;
