import React, { useState, useEffect } from 'react';
import SubNavbar from '../components/Header/SubNavbar';
import ReportChart from '../components/charts/ReportChart';
import { Form, Input, message, Select, Space, Button, DatePicker } from 'antd';
import { FaFilter, FaDownload } from 'react-icons/fa';
import dayjs from 'dayjs';
import { BASE_URL } from '../components/Constant/config';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PreLoader from '../components/PreLoader/PreLoader';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

function HealthReport() {
  const { Option } = Select;
  const [reportSearchForm] = Form.useForm();
  const [reportFilterForm] = Form.useForm();
  const [isLoader, setIsLoader] = useState(true);
  const [isRangeType, setIsRangeType] = useState('');
  const [reportDataForChart, setReportDataForChart] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [leadersList, setLeadersList] = useState([]);
  const changeDateRageType = (value) => {
    setIsRangeType(value);
  };
  const searchHealthReport = async () => {    
    try {
      const token = localStorage.getItem('token');
      const reportSearchData = {
        "dateType" : reportSearchForm.getFieldValue('dateType'),
        "leaders" : reportFilterForm.getFieldValue('leaders'),
        "partners" : reportFilterForm.getFieldValue('partners'),
      };
      if(reportSearchForm.getFieldValue('dateType') === 'custom'){
        const dateRangeForReport = reportSearchForm.getFieldValue('dateRangeForReport');
        reportSearchData.dateFrom = dayjs(dateRangeForReport[0]).format('DD/MM/YYYY');
        reportSearchData.dateTo = dayjs(dateRangeForReport[1]).format('DD/MM/YYYY');
      }
      const response = await fetch(`${BASE_URL}/health-report/get-health-report`,{
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(reportSearchData),
      });
      const result = await response.json();
      if (result.statusCode === 200) {    
        let tlData = [];
        let partnerData = [];
        let dateType = reportSearchForm.getFieldValue('dateType');
        if(dateType !== 'today' && dateType !== 'yesterday'){
          Object.entries(result.data[dateType]).map(([reportDate, detail]) => (
            tlData.push({ label: reportDate, y: parseFloat(detail.leaderPercentage.toFixed(2)) }),
            partnerData.push({ label: reportDate, y: parseFloat(detail.partnerPercentage.toFixed(2)) })
          ));
        } else {
          const yesterday = dayjs().subtract(1, 'day').format('DD-MM-YYYY');
          const today = dayjs().format('DD-MM-YYYY');
          tlData.push({ label: dateType === 'today' ? today : yesterday, y: parseFloat(result.data[dateType].leaderPercentage.toFixed(2)) });
          partnerData.push({ label: dateType === 'today' ? today : yesterday, y: parseFloat(result.data[dateType].partnerPercentage.toFixed(2)) });
        }
        setReportDataForChart([
          {
            type: "column",
            color: "#7CB5EC",
            name: "Partner Health Report",
            legendText: "Partner Health Report",
            showInLegend: true,
            dataPoints: partnerData
          },
          {
            type: "column",	
            color: "#434348",
            name: "TL Health Report",
            legendText: "TL Health Report",
            axisYType: "secondary",
            showInLegend: true,
            dataPoints: tlData
          }
        ]);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.error('Error fetching data:', error);
    }
  }
  const getPartnersList = async () => {   
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/get-users?role=partner&limit=500000&page=1`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) { 
        setPartnersList(result.data);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  }
  const getLeadersList = async () => {    
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/get-users?role=leader&limit=500000&page=1`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) { 
        setLeadersList(result.data);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  }

  function onSearch(val) {
    console.log('search:', val);
  }
  useEffect(() => {
    reportSearchForm.setFieldsValue({ 
      dateType: "week"
    });
    setIsRangeType("week");
    searchHealthReport();
    getPartnersList();
    getLeadersList();
  }, []);
  return (
    <>
      {isLoader ? (
        <PreLoader/>
      ):''}
      <SubNavbar pageName="Health Report" buttons={[]} />
      <div className="row">
        <div className="col-12">
          <Form form={reportSearchForm}>
            <Space>
              <div class="form-group mb-2">
                <label for="staticEmail2" class="sr-only">
                  <strong>Report Peroid:</strong>
                </label>
              </div>
              <div class="form-group mb-2">
                <span className="icon-report">i</span>
              </div>
              <div class="form-group mb-2">   
                <Form.Item name="dateType" label="" rules={[{ required: true, message: 'Please select date!'}]}>          
                  <Select style={{ width: '150px' }} onChange={changeDateRageType}>
                      <Option value="custom">Custom Date</Option>
                      <Option value="yesterday">Yesterday</Option>
                      <Option value="today">Today</Option>
                      <Option value="month">Current Month</Option>
                      <Option value="week">Current Week</Option>
                      <Option value="lastMonth">Last Month</Option>
                      <Option value="lastWeek">Last Week</Option>
                  </Select>
                </Form.Item>             
              </div>
              {isRangeType === "custom" ?
              <div class="form-group mb-2">  
                <Form.Item name="dateRangeForReport" label="" rules={[{ required: true, message: 'Please select date!'}, { validator: validateDateRange }]}>
                  <RangePicker 
                    format={dateFormat}   
                    maxDate={dayjs(new Date())}
                  />
                </Form.Item> 
              </div>
              : ""}
              <div class="form-group mb-2"> 
                <span class="iconFilter" onClick={searchHealthReport}><FaFilter/></span>
              </div>
              <div class="form-group mb-2"> 
                <span class="iconFilter"><FaDownload/></span>
              </div>
            </Space>
          </Form>
          <Form form={reportFilterForm}>
            <Space className="mt-2">
              <div class="form-group mb-2">
                <label for="staticEmail2" class="sr-only">
                  <strong>Partners:</strong>
                </label>
              </div>
              <div class="form-group mb-2">   
                <Form.Item name="partners" label="">          
                  <Select                      
                    style={{ width: '250px' }} 
                    placeholder="Select Partners" 
                    mode="multiple" 
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {partnersList.map(partner => (
                        <Option key={partner._id} value={partner._id}>{partner.companyName}</Option>
                    ))}   
                  </Select>
                </Form.Item>             
              </div>
              <div class="form-group mb-2">
                <label for="staticEmail2" class="sr-only">
                  <strong>Team Leads:</strong>
                </label>
              </div>
              <div class="form-group mb-2">   
                <Form.Item name="leaders" label="">          
                  <Select                      
                    style={{ width: '250px' }} 
                    placeholder="Select Team Leads" 
                    mode="multiple" 
                    allowClear 
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {leadersList.map(leader => (
                        <Option key={leader._id} value={leader._id}>{leader.name}</Option>
                    ))}   
                  </Select>
                </Form.Item>             
              </div>
              <div class="form-group mb-2"> 
                <button class="btn btn-sm btn-success" onClick={searchHealthReport}>Apply</button>
              </div>
              <div class="form-group mb-2"> 
                <button class="btn btn-sm btn-danger">Clear</button>
              </div>
            </Space>
          </Form>
        </div>
      </div>  
      <div className="row mt-4">
        <div className="col-10 offset-1">
            <ReportChart reportData={reportDataForChart}/>
        </div>
      </div>  
    </>
  );
}
export default HealthReport;

function validateDateRange(rule, value, callback) {
  if (!value || value.length !== 2) {
    callback('Please select a start and end date.');
    return;
  }
  const startDate = value[0];
  const endDate = value[1];
  // Calculate the difference in days
  const daysDifference = dayjs(endDate).diff(startDate, 'day');
  if (daysDifference > 30) {
    callback('Date range cannot be more than 30 days.');
  } else {
    callback(); // Validation passes
  }
}