import React, { useState, useRef } from 'react';
import { Table, Modal, Form, Input, message, Select, Upload, Button, Image } from 'antd';
import SubNavbar from '../Header/SubNavbar';
import { FaEdit, FaTrash, FaListUl } from 'react-icons/fa';
import PreLoader from '../PreLoader/PreLoader';
import { UploadOutlined } from '@ant-design/icons';
import CustomButton from '../Button/CustomButton';
import { BASE_URL } from '../Constant/config';
import { useEffect } from 'react';
import Swal from 'sweetalert2';

let allstatusText = [];
allstatusText['active'] = 'Active';
allstatusText['inactive'] = 'Inactive';
allstatusText['kyc_pending'] = 'KYC Pending';
allstatusText['kyc_rejected'] = 'KYC Rejected';
allstatusText['kyc_verified'] = 'KYC Approved';
let allstatusColors = [];
allstatusColors['active'] = '#238B21';
allstatusColors['inactive'] = '#A9A9A9';
allstatusColors['kyc_pending'] = '#FF4500';
allstatusColors['kyc_rejected'] = '#8B0000';
allstatusColors['kyc_verified'] = '#00469A';

const ManufacturedDataTable = () => {
  const [modalType, setModalType] = useState('');
  const [manufactureForm] = Form.useForm();
  const [isLoader, setIsLoader] = useState(true);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [leadersData, setLeadersData] = useState([]);
  const [perPageItem, setPerPageItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [currentUserDetails, setCurrentUserDetails] = useState({});
  const { Option } = Select;

  const [filters, setFilters] = useState({
    Manufacturer: '',
    Partners: '',
    Company: '',
    POC: '',
    Mobile: '',
    Email: '',
    GST: '',
    PAN: '',
    Associate: '',
    Batteries: '',
    Address: ''
  });

  useEffect(() => {
    fetchData(currentPage, perPageItem);
  }, []);

  const fetchData = async (page, pageSize) => {
    setIsLoader(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/get-users?role=manufacturer&limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        const data = result.data.map((item, index) => ({
          key: item.user,
          _id: item._id,
          rowData: item,
          Manufacturer: item.uniqueCode,
          Company: item.companyName,
          POC: item.pocName,
          PAN: (item.companyPAN ? item.companyPAN : '-'),
          GST: (item.gstNumber ? item.gstNumber : '-'),
          Email: (item.email ? item.email : '-'),
          Mobile: item.mobile.toString(),
          Address: item.address,
          Batteries: item.batteries ? item.batteries : "-",
        }));
        setDataSource(data);
        setTotalItems(result.totalCount);
        setIsLoader(false);
      } else {
        console.error('Failed to fetch data:', result.message);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  };

  const handleEdit = async (event, record) => {
    event.preventDefault();
    manufactureForm.resetFields();
    setIsLoader(true);
    try {
      const token = localStorage.getItem('token');
      const userId = record._id;
      const response = await fetch(`${BASE_URL}/manufacturer/get-manufacturer/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setCurrentUserDetails(result.data);
        showModal('edit', record._id);   
        setIsLoader(false);
      } else {
        console.error('Failed to fetch data:', result.message); 
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error); 
      setIsLoader(false);
    }
    manufactureForm.setFieldsValue({ 
      companyName: record.rowData.companyName,
      pocName: record.rowData.pocName,
      mobile: record.rowData.mobile,
      email: record.rowData.email,
      address: record.rowData.address,
      city: record.rowData.city,
      state: record.rowData.state,
      pincode: record.rowData.pincode,
      gstNumber: record.rowData.gstNumber,
      companyPAN: record.rowData.companyPAN,
      bankName: record.rowData.bankName,
      accountNumber: record.rowData.accountNumber,
      ifscCode: record.rowData.ifscCode
    });
  };

  const handleDelete = async (event, record) => {
    event.preventDefault();
    let deleteUsers = { users: [] };
    if(record?._id){
      deleteUsers.users = [record.rowData.user];
    } else {      
      if(selectedRowKeys.length !== 0){
        deleteUsers.users = selectedRowKeys;
      }
    }    
    if(deleteUsers.users.length > 0) {
      Swal.fire({
        title: "Are you sure? ",
        html: "You want to delete <br/>You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
            confirmButton: "btn btn-success mx-1",
            cancelButton: "btn btn-danger mx-1"
        },
        buttonsStyling: !1,
      }).then(async function (t) {
          if(t.isConfirmed === true){
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/admin/delete-user/`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(deleteUsers),
            });
            const result = await response.json();
            if(result.statusCode === 200){
              message.success(result.message);
              fetchData(currentPage, perPageItem);
            } else {
              message.error('Error');
            }
          }
      });
    }
  };

  const handleAddClick = () => {
    setCurrentUserDetails({});
    manufactureForm.resetFields();
    showModal('add');
  };

  const showModal = (type, record = null) => {
    setModalType(type);
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => { 
    if (modalType === 'add') {
      const validationResult = manufactureForm.validateFields(['companyName', 'pocName', 'mobile', 'email', 'address', 'city', 'state', 'pincode', 'gstNumber', 'gstURL', 'companyPAN', 'panURL', 'bankName', 'accountNumber', 'ifscCode', 'cancelledChequeURL']).then(async (values) => {
        setIsLoader(true);
        try {
          const token = localStorage.getItem('token');
          const createFormData = manufactureForm.getFieldsValue(true);        
          const FormData = require('form-data');
          const formData = new FormData();
          for (const key in createFormData) {
            formData.append(key, createFormData[key]);
          }        
          createFormData['panURL'].fileList ? formData.append('panURL', createFormData['panURL'].fileList[0].originFileObj) : formData.delete("panURL");
          createFormData['gstURL'].fileList ? formData.append('gstURL', createFormData['gstURL'].fileList[0].originFileObj) : formData.delete("gstURL");
          createFormData['cancelledChequeURL'].fileList ? formData.append('cancelledChequeURL', createFormData['cancelledChequeURL'].fileList[0].originFileObj) : formData.delete("cancelledChequeURL");
          const response = await fetch(`${BASE_URL}/admin/create-manufacturer`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`
            },
            body: formData,
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success('Manufacturer created successfully');
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
            setIsLoader(false);
          } else {
            message.error('Failed to create manufacturer - ' + result.message);
            setIsLoader(false);
          }
        } catch (error) {
          message.error('Error creating manufacturer', error);
          setIsLoader(false);
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    } else {
      const validationResult = manufactureForm.validateFields(['companyName', 'pocName', 'mobile', 'email', 'address', 'city', 'state', 'pincode', 'gstNumber', 'companyPAN', 'bankName', 'accountNumber', 'ifscCode']).then(async (values) => {
        setIsLoader(true);
        try {
          const token = localStorage.getItem('token');
          const createFormData = manufactureForm.getFieldsValue(true);        
          const FormData = require('form-data');
          const formData = new FormData();
          for (const key in createFormData) {
            formData.append(key, createFormData[key]);
          }
          createFormData['panURL'] ? createFormData['panURL'].fileList ? formData.append('panURL', createFormData['panURL'].fileList[0].originFileObj) : formData.delete("panURL") : formData.delete("panURL");
          createFormData['gstURL'] ? createFormData['gstURL'].fileList ? formData.append('gstURL', createFormData['gstURL'].fileList[0].originFileObj) : formData.delete("gstURL") : formData.delete("gstURL");
          createFormData['cancelledChequeURL'] ? createFormData['cancelledChequeURL'].fileList ? formData.append('cancelledChequeURL', createFormData['cancelledChequeURL'].fileList[0].originFileObj) : formData.delete("cancelledChequeURL") : formData.delete("cancelledChequeURL");
          const manufacturerId = currentRecord; 
          const response = await fetch(`${BASE_URL}/manufacturer/update-manufacturer/${manufacturerId}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success('Manufacturer updated successfully');
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
            setIsLoader(false);
          } else {
            message.error('Failed to update manufacturer - ' + result.message);
            setIsLoader(false);
          }
        } catch (error) {
          setIsLoader(false);
          message.error('Error updateing manufacturer');
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleExportClick = async () => {
    setIsLoader(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/export-users?role=manufacturer`,{
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      const result = await response.json();
      if(result.statusCode === 200){
        //handleDownload(result.csvLink);
        let newObjectWithLabels = [];
        newObjectWithLabels.push(result.csvLabels);
        result.csvData.forEach(element => {
            newObjectWithLabels.push(element);
        });
        const csvData = new Blob([convertToCSV(newObjectWithLabels)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `manufacturers.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setIsLoader(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  }

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const handleDownload = (csvLink) => {
    if (csvLink) {
      const link = document.createElement('a');
      link.href = csvLink;
      link.setAttribute('download', 'manufacturers.csv'); // Set the desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const Buttons = [
    { text: 'Export', onClick: handleExportClick },
    { text: 'Add', onClick: handleAddClick },
    { text: 'Delete', onClick: handleDelete },
  ];

  const filteredData = dataSource.filter(item =>
    (filters.Manufacturer === '' || item.Manufacturer.toLowerCase().includes(filters.Manufacturer.toLowerCase())) &&
    (filters.Partners === '' || item.Partners.toLowerCase().includes(filters.Partners.toLowerCase())) &&
    (filters.Company === '' || item.Company.toLowerCase().includes(filters.Company.toLowerCase())) &&
    (filters.Email === '' || item.Email.toLowerCase().includes(filters.Email.toLowerCase())) &&
    (filters.POC === '' || item.POC.toLowerCase().includes(filters.POC.toLowerCase())) &&
    (filters.Mobile === '' || item.Mobile.toLowerCase().includes(filters.Mobile.toLowerCase())) &&
    (filters.GST === '' || item.GST.toLowerCase().includes(filters.GST.toLowerCase())) &&
    (filters.PAN === '' || item.PAN.toLowerCase().includes(filters.PAN.toLowerCase())) &&
    (filters.Associate === '' || item.Associate.toLowerCase().includes(filters.Associate.toLowerCase())) &&
    (filters.Batteries === '' || item.Batteries.toLowerCase().includes(filters.Batteries.toLowerCase())) &&
    (filters.Address === '' || item.Address.toLowerCase().includes(filters.Address.toLowerCase()))
  );

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setCurrentPage(pagination.current);
    setPerPageItem(pagination.pageSize);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFilterChange = (dataIndex, value) => {
    setFilters((prev) => ({
      ...prev,
      [dataIndex]: value,
    }));
  };

  const columns = [
    {
      title: (
        <div>
          <div>Manufactured Code</div>
          <Input
            placeholder="Manufactured Code"
            value={filters.Manufacturer}
            onChange={(e) => handleFilterChange('Manufacturer', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Manufacturer',
      sorter: (a, b) => a.Manufacturer.localeCompare(b.Manufacturer),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Company Name</div>
          <Input
            placeholder="Company Name"
            value={filters.Company}
            onChange={(e) => handleFilterChange('Company', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Company',
      width: 150,
      sorter: (a, b) => a.Company.localeCompare(b.Company),
      width: 200,
    },
    {
      title: (
        <div>
          <div>POC</div>
          <Input
            placeholder="POC"
            value={filters.POC}
            onChange={(e) => handleFilterChange('POC', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'POC',
      width: 200,
      sorter: (a, b) => a.POC.localeCompare(b.POC),
    },
    {
      title: (
        <div>
          <div>Mobile</div>
          <Input
            placeholder="Mobile"
            value={filters.Mobile}
            onChange={(e) => handleFilterChange('Mobile', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Mobile',
      width: 200,
      sorter: (a, b) => a.Mobile.localeCompare(b.Mobile),
    },
    {
      title: (
        <div>
          <div>Email</div>
          <Input
            placeholder="Email"
            value={filters.Email}
            onChange={(e) => handleFilterChange('Email', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Email',
      width: 200,
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: (
        <div>
          <div>GST</div>
          <Input
            placeholder="GST"
            value={filters.GST}
            onChange={(e) => handleFilterChange('GST', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'GST',
      width: 200,
      sorter: (a, b) => a.GST.localeCompare(b.GST),
    },
    {
      title: (
        <div>
          <div>PAN</div>
          <Input
            placeholder="PAN"
            value={filters.PAN}
            onChange={(e) => handleFilterChange('PAN', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'PAN',
      width: 200,
      sorter: (a, b) => a.PAN.localeCompare(b.PAN),
    },
    {
      title: (
        <div>
          <div>Address</div>
          <Input
            placeholder="Address"
            value={filters.Address}
            onChange={(e) => handleFilterChange('Address', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      width: 200,
      dataIndex: 'Address',
      sorter: (a, b) => a.Address.localeCompare(b.Address),
    },
    {
      title: (
        <div>
          <div>Batteries</div>
          <Input
            placeholder="Batteries"
            value={filters.Batteries}
            onChange={(e) => handleFilterChange('Batteries', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      width: 200,
      dataIndex: 'Batteries',
      sorter: (a, b) => a.Batteries.localeCompare(b.Batteries),    
      render: (_, record) => (
        <>
          <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>{record.Batteries.total}</span>
          <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>=</span>
          <span style={{'background':allstatusColors['active'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.active}</span>
          <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
          <span style={{'background':allstatusColors['kyc_pending'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.pending_for_allocation}</span>
        </>
      )
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (text, record) => (
        <span>
          <a href='#' onClick={(event) => handleEdit(event, record)}>
            <FaEdit style={{ cursor: 'pointer', marginRight: 16 }} />
          </a>
          <a href='#' onClick={(event) => handleDelete(event, record)}>
            <FaTrash style={{ cursor: 'pointer' }} />
          </a>
        </span>
      ),
    },
  ];
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    // setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  const handleChangeSelectTL = (value) => {
    // setFormData(prev => ({ ...prev, teamLead: value }));
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return false;
    },
    accept: 'image/*',
    maxCount: 1
  };

  return (
    <>
      {isLoader ? (
        <PreLoader/>
      ):''}
      <SubNavbar pageName="Manage Manufacturer" buttons={Buttons} />
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive mt-2">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredData}
              onChange={onChange}
              scroll={{y: `calc(100vh - 280px)`}}
              pagination={{
                simple: false,
                size:"small",
                total: totalItems,
                showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                showQuickJumper: false,
                showLessItems: true,
                responsive:true,
                defaultPageSize:perPageItem,
                pageSizeOptions:[5,10,20,50,100],
                position: ['topRight'],
                onChange: (page, pageSize) => {
                  console.log(page, pageSize);
                  fetchData(page, pageSize);
                }
              }}
              bordered
            />
          </div>
        </div>
      </div>      
      <Modal
        title={modalType === 'edit' ? 'Edit Manufacturer' : 'Add Manufacturer'}
        visible={isModalVisible}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[
          <CustomButton key="submit" text={modalType === 'edit' ? 'Save changes' : 'Add'} onClick={handleOk} />
        ]}
        width={750}
      >
        <Form form={manufactureForm} encType='multipart/form-data'>
          <div className="row my-2">
            <div className="col-6">
              <h6>General Details</h6>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="companyName" className="form-label">Company Name:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="companyName" label="" rules={[{ required: true, message: 'Please enter company name!' }]}>
                    <Input
                      placeholder="Company Name"
                      className="form-control"
                    />
                  </Form.Item> 
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="pocName" className="form-label">POC Name:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="pocName" label="" rules={[{ required: true, message: 'Please enter poc name!' }]}>
                    <Input
                      placeholder="POC Name"
                      className="form-control"
                    />
                  </Form.Item> 
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="mobile" className="form-label">Mobile:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="mobile" label="" rules={[{ required: true, message: 'Please enter mobile!' }]}>
                    <Input
                      placeholder="Mobile"
                      className="form-control"
                    />
                  </Form.Item> 
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="email" className="form-label">E-Mail:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="email" label="" rules={[{ required: true, message: 'Please enter email!' }]}>
                    <Input
                      placeholder="test@test.com"
                      className="form-control"
                    />
                  </Form.Item> 
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="address" className="form-label">Address:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="address" label="" rules={[{ required: true, message: 'Please enter address!' }]}>
                    <Input
                      placeholder="Address"
                      className="form-control"
                    />
                  </Form.Item> 
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="city" className="form-label">City:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="city" label="" rules={[{ required: true, message: 'Please enter city!' }]}>
                    <Input
                      placeholder="City"
                      className="form-control"
                    />
                  </Form.Item> 
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="state" className="form-label">State:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="state" label="" rules={[{ required: true, message: 'Please enter state!' }]}>
                    <Input
                      placeholder="State"
                      className="form-control"
                    />
                  </Form.Item>                      
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="pincode" className="form-label">Pin Code:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="pincode" label="" rules={[{ required: true, message: 'Please enter pincode!' }]}>
                    <Input
                      placeholder="Pin Code"
                      className="form-control"
                    />
                  </Form.Item>  
                </div>
              </div>
            </div>
            <div className="col-6">
              <div>
                <h6>Company Details</h6>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="gstNumber" className="form-label">GST Number:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="gstNumber" label="" rules={[{ required: true, message: 'Please enter gst no.!' }]}>
                      <Input
                        placeholder="GST Number"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="gstURL" className="form-label">GST Certificate:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="gstURL" label="" rules={[{ required: true, message: 'Please upload gst image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item> 
                    {currentUserDetails.gstURL ?                  
                    <Image
                      width={30}
                      height={30}
                      src={currentUserDetails.gstURL}
                    /> : ''}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="companyPAN" className="form-label">Company PAN:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="companyPAN" label="" rules={[{ required: true, message: 'Please enter company pan!' }]}>
                      <Input
                        placeholder="Company PAN"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="panURL" className="form-label">PAN:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="panURL" label="" rules={[{ required: true, message: 'Please upload pan image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item>
                    {currentUserDetails.panURL ?                  
                    <Image
                      width={30}
                      height={30}
                      src={currentUserDetails.panURL}
                    /> : ''}
                  </div>
                </div>
              </div>
              <div>
                <h6>Bank Details</h6>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="bankName" className="form-label">Bank Name:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="bankName" label="" rules={[{ required: true, message: 'Please enter bank name!' }]}>
                      <Input
                        placeholder="Bank Name"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="accountNumber" className="form-label">Account Number:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="accountNumber" label="" rules={[{ required: true, message: 'Please enter account no.!' }]}>
                      <Input
                        placeholder="Account Number"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="ifscCode" className="form-label">IFSC Code:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="ifscCode" label="" rules={[{ required: true, message: 'Please enter ifsc code!' }]}>
                      <Input
                        placeholder="IFSC Code"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="cancelledChequeURL" className="form-label">Cancelled Cheque:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="cancelledChequeURL" label="" rules={[{ required: true, message: 'Please upload cancelled cheque image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item>
                    {currentUserDetails.cancelledChequeURL ?                  
                    <Image
                      width={30}
                      height={30}
                      src={currentUserDetails.cancelledChequeURL}
                    /> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ManufacturedDataTable;