import React, { useState } from 'react';
import { Form, Alert, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomButton from '../../components/Button/CustomButton';
import SideImg from '../../assests/power-hub-logo.jpg';
import { BASE_URL } from '../../components/Constant/config';
import './Login.css';
import LoginOtp from './LoginOtp.js';

const Login = ({ onLoginSuccess }) => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [error, setError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const validateMobileNumber = (number) => {
        const mobileNumberPattern = /^[0-9]{10}$/;
        return mobileNumberPattern.test(number);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateMobileNumber(mobileNumber)) {
            try {
                const response = await fetch(`${BASE_URL}/auth/login/sendotp`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ mobile: mobileNumber }),
                });
                const data = await response.json();
                if (response.ok) {
                    setError('');
                    setIsSubmitted(true);
                    setMobileNumber(mobileNumber);
                } else {
                    setError(data.message || 'Something went wrong. Please try again.');
                }
            } catch (err) {
                setError('Network error. Please try again later.');
            }
        } else {
            setError('Please enter a valid 10-digit mobile number.');
        }
    };

    return (
        isSubmitted ? (
            <LoginOtp onOtpSuccess='true' mobileNumber={mobileNumber}/>
        ) : (
            <Container className="login-container">
                <div className="login-content">
                    <img src={SideImg} className="sideImg" alt="Side" />
                    <div className="form-container">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formMobileNumber">
                                <Form.Label>
                                    Hello! Login to <span className="power-hub">Power HUB</span>
                                </Form.Label>
                                <div className="input-groups">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your mobile number"
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                    />
                                </div>
                                <p className="otp-text mt-1">We will send an OTP on this number for confirmation</p>
                                {error && <Alert variant="danger" className="mt-2 error-text">{error}</Alert>}
                                {isSubmitted && !error && <Alert variant="success" className="error-text mt-3">Login successful</Alert>}
                            </Form.Group>
                            <div className="button-container">
                                <CustomButton text="Login" />
                            </div>
                        </Form>
                    </div>
                </div>
            </Container>
        )
    );
};

export default Login;
