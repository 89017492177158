import React from 'react';
import ManufacturedDataTable from '../components/Table/ManufacturedDataTable';

function Manufactured() {  
  return (
    <div>
          <ManufacturedDataTable/>
    </div>
  );
}

export default Manufactured;


