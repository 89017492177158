import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Select, message, Table, Modal, Space, Upload, Button, DatePicker, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import SubNavbar from '../Header/SubNavbar';
import { FaEdit } from 'react-icons/fa';
import CustomButton from '../Button/CustomButton';
import { BASE_URL } from '../Constant/config';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PreLoader from '../PreLoader/PreLoader';
dayjs.extend(customParseFormat);

let allstatusText = [];
allstatusText['new'] = 'New';
allstatusText['open'] = 'Open';
allstatusText['battery_in_service'] = 'Battery In Service';
allstatusText['closed_by_partner'] = 'Closed By Partner';
allstatusText['resolved_by_leader'] = 'Resolved By Leader';
allstatusText['resolved_by_partner'] = 'Resolved By Partner';
allstatusText['closed_by_driver'] = 'Closed By Driver';
allstatusText['resolved_by_admin'] = 'Resolved By Admin';
let allstatusColors = [];
allstatusColors['new'] = '#8B0101';
allstatusColors['open'] = '#03479A';
allstatusColors['battery_in_service'] = '#FD4501';
allstatusColors['closed_by_partner'] = '#A9A9A9';
allstatusColors['resolved_by_leader'] = '#A9A9A9';
allstatusColors['resolved_by_partner'] = '#278A24';
allstatusColors['closed_by_driver'] = '#278A24';
allstatusColors['resolved_by_admin'] = '#278A24';

const HelpTicketDataTable = () => {
  const [paymentForm] = Form.useForm();
  const [isLoader, setIsLoader] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [perPageItem, setPerPageItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [editTicketDetails, setEditTicketDetails] = useState({});
  const [filters, setFilters] = useState({
    batteryBoxSerialNumber: '',
    driver: '',
    partner: '',
    teamLead: '',
    servicePerson: '',
    category: '',
    issueDetails: '',
    createdBy: '',
    createdAt: '',
    rootCause: '',
    workaround: '',
    resolvedBy: '',
    revolutionDate: '',
    status: '',
  });

  useEffect(() => {
    fetchData(currentPage, perPageItem);
  }, []);

  const fetchData = async (page, pageSize) => {
    setIsLoader(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/help-ticket/get-help-tickets?limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setDataSource(result?.data?.tickets);
        setTotalItems(result?.data?.pagination?.total);
      } else {
        console.error('Failed to fetch data:', result.message);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.error('Error fetching data:', error);
    }
  };

  const handleExportClick = () => {
    console.log('handleExportClick');
  };

  const Buttons = [
    { text: 'Export', onClick: handleExportClick },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setCurrentPage(pagination.current);
    setPerPageItem(pagination.pageSize);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFilterChange = (dataIndex, value) => {
    setFilters((prev) => ({
      ...prev,
      [dataIndex]: value,
    }));
  };

  const handleUpdateTicket = async (event, record) => {
      event.preventDefault();  
      setIsLoader(true); 
      try {
          const token = localStorage.getItem('token');
          const ticketId = record._id;
          const response = await fetch(`${BASE_URL}/help-ticket/get-help-ticket/${ticketId}`, {
              headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              },
          });
          const result = await response.json();
          if (result.statusCode === 200) {
              paymentForm.resetFields();
              setEditTicketDetails(result.data);
              setIsModalVisible(true);
              setIsLoader(false);  
          } else {
              console.error('Failed to fetch data:', result.message);
              setIsLoader(false);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
          setIsLoader(false);
      }
  }

  const handleOk = async () => {
    console.log('handleOk');
  }

  const handleCancel = async () => {
    setIsModalVisible(false);
  }

  const filteredDataSource = dataSource.filter((item) =>
    Object.keys(filters).every((key) =>
      item[key]?.toString().toLowerCase().includes(filters[key].toLowerCase())
    )
  );

  const columns = [
    {
      title: (
        <div>
          <div>Battery Code</div>
          <Input
            placeholder="Search Battery"
            value={filters.batteryBoxSerialNumber}
            onChange={(e) => handleFilterChange('batteryBoxSerialNumber', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'batteryBoxSerialNumber',
      width: 200,
      sorter: (a, b) => a?.batteryBoxSerialNumber?.toString()?.toString().localeCompare(b?.batteryBoxSerialNumber?.toString()),
      render: (_, record) => (
        <span>{record?.battery?.batteryBoxSerialNumber}</span>
      )
    },
    {
      title: (
        <div>
          <div>Driver</div>
          <Input
            placeholder="Search Driver"
            value={filters.driver}
            onChange={(e) => handleFilterChange('driver', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'driver',
      width: 200,
      sorter: (a, b) => a?.driver?.toString().localeCompare(b?.driver?.toString()),
      render: (_, record) => (
        <span>{record?.driver?.name}</span>
      )
    },
    {
      title: (
        <div>
          <div>Partner</div>
          <Input
            placeholder="Search Partner"
            value={filters.partner}
            onChange={(e) => handleFilterChange('partner', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'partner',
      width: 200,
      sorter: (a, b) => a?.partner?.toString().localeCompare(b?.partner?.toString()),
      render: (_, record) => (
        <span>{record?.partner?.companyName}</span>
      )
    },
    {
      title: (
        <div>
          <div>Team Lead</div>
          <Input
            placeholder="Search Team Lead"
            value={filters.teamLead}
            onChange={(e) => handleFilterChange('teamLead', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'teamLead',
      width: 200,
      sorter: (a, b) => a?.teamLead?.toString().localeCompare(b?.teamLead?.toString()),
      render: (_, record) => (
        <span>{record?.teamLead?.name}</span>
      )
    },
    {
      title: (
        <div>
          <div>Service Person</div>
          <Input
            placeholder="Search Service Person"
            value={filters.servicePerson}
            onChange={(e) => handleFilterChange('servicePerson', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'servicePerson',
      width: 200,
      sorter: (a, b) => a?.servicePerson?.toString().localeCompare(b?.servicePerson?.toString()),
      render: (_, record) => (
        <span>{record?.location?.servicePersonName}</span>
      )
    },
    {
      title: (
        <div>
          <div>Issue Category</div>
          <Input
            placeholder="Search Issue Category"
            value={filters.category}
            onChange={(e) => handleFilterChange('category', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'category',
      width: 200,
      sorter: (a, b) => a?.category?.toString().localeCompare(b?.category?.toString()),
      render: (_, record) => (
        <span>{record?.category?.category}</span>
      )
    },
    {
      title: (
        <div>
          <div>Issue Details</div>
          <Input
            placeholder="Search Issue Details"
            value={filters.issueDetails}
            onChange={(e) => handleFilterChange('issueDetails', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'issueDetails',
      width: 200,
      sorter: (a, b) => a?.issueDetails?.toString().localeCompare(b?.issueDetails?.toString()),
      render: (_, record) => (
        <span>{record?.issueDetails}</span>
      )
    },
    {
      title: (
        <div>
          <div>Created By</div>
          <Input
            placeholder="Search Created By"
            value={filters.createdBy}
            onChange={(e) => handleFilterChange('createdBy', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'createdBy',
      width: 120,
      sorter: (a, b) => a?.createdBy?.toString().localeCompare(b?.createdBy?.toString()),
      render: (_, record) => (
        <span className="text-capitalize">{record?.createdBy}</span>
      )
    },
    {
      title: (
        <div>
          <div>Created Date</div>
          <Input
            placeholder="Created Date"
            value={filters.createdAt}
            onChange={(e) => handleFilterChange('createdAt', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'createdAt',
      width: 150,
      sorter: (a, b) => a?.createdAt?.toString().localeCompare(b?.createdAt?.toString()),
      render: (_, record) => (
        <>
          {record.createdAt ? (
            dayjs(record.createdAt).format('DD/MM/YYYY')
          ) : (
            '-'
          )}
        </>
      )
    },
    {
      title: (
        <div>
          <div>Root Cause</div>
          <Input
            placeholder="Search Root Cause"
            value={filters.rootCause}
            onChange={(e) => handleFilterChange('rootCause', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'rootCause',
      width: 200,
      sorter: (a, b) => a?.rootCause?.toString().localeCompare(b?.rootCause?.toString()),
      render: (_, record) => (
        <span>{record?.rootCause}</span>
      )
    },
    {
      title: (
        <div>
          <div>Workaround</div>
          <Input
            placeholder="Search Workaround"
            value={filters.workaround}
            onChange={(e) => handleFilterChange('workaround', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'workaround',
      width: 120,
      sorter: (a, b) => a?.workaround?.toString().localeCompare(b?.workaround?.toString()),
      render: (_, record) => (
        <span>{record?.workaround}</span>
      )
    },
    {
      title: (
        <div>
          <div>Resolved By</div>
          <Input
            placeholder="Search Resolved By"
            value={filters.resolvedBy}
            onChange={(e) => handleFilterChange('resolvedBy', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'resolvedBy',
      width: 150,
      sorter: (a, b) => a?.resolvedBy?.toString().localeCompare(b?.resolvedBy?.toString()),
      render: (_, record) => (
        <span>{record?.resolvedBy?.name}</span>
      )
    },
    {
      title: (
        <div>
          <div>Revolution Date</div>
          <Input
            placeholder="Search Revolution Date"
            value={filters.revolutionDate}
            onChange={(e) => handleFilterChange('revolutionDate', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'revolutionDate',
      width: 170,
      sorter: (a, b) => a?.revolutionDate?.toString()?.toString().localeCompare(b?.revolutionDate?.toString()),
      render: (_, record) => (
        <>
          {record.revolutionDate ? (
            dayjs(record.revolutionDate).format('DD/MM/YYYY')
          ) : (
            '-'
          )}
        </>
      )
    },
    {
      title: (
        <div>
          <div>Status</div>
          <Input
            placeholder="Search Status"
            value={filters.status}
            onChange={(e) => handleFilterChange('status', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'status',
      width: 170,
      sorter: (a, b) => a?.status?.toString()?.toString().localeCompare(b?.status?.toString()),     
      render(text, record) {
        return {
          props: {
            style: { 
              'background': record.status ? allstatusColors[record.status] : "",
              'color' : "#fff",
              'font-weight' :  "500"
            }
          },
          children: record.status ? allstatusText[record.status] : '-'
        };
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      width: 80,
      render: (_, record) => (
        <>
          {record.status !== 'resolved_by_admin' ? (
            <a href='#' onClick={(event) => handleUpdateTicket(event, record)}>
                <FaEdit style={{ cursor: 'pointer' }} />
            </a>
          ) : (
            ''
          )}
        </>
      )
    }
  ];

  const uploadProps = {
    beforeUpload: (file) => {
      return false;
    },
    accept: 'image/*',
    maxCount: 1
  };

  return (
    <>
      {isLoader ? (
        <PreLoader/>
      ):''}
      <SubNavbar
        pageName="Help Tickets"
        buttons={Buttons}
      />
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive mt-2">
            <Table
              rowKey={(record) => record._id}
              columns={columns}
              dataSource={dataSource}
              onChange={onChange}
              width={700}
              scroll={{y: `calc(100vh - 250px)`}}
              pagination={{
                simple: false,
                size:"small",
                total: totalItems,
                showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                showQuickJumper: false,
                showLessItems: true,
                responsive:true,
                defaultPageSize:perPageItem,
                pageSizeOptions:[5,10,20,50,100],
                position: ['topRight'],
                onChange: (page, pageSize) => {
                  //console.log(page, pageSize);
                  fetchData(page, pageSize);
                }
              }}
              bordered
            />
          </div>
        </div>
      </div>                 
      <Modal
          title="Update Ticket"
          visible={isModalVisible} 
          onOk={handleOk} 
          centered
          onCancel={handleCancel}
          footer={[
              <CustomButton key="submit" onClick={handleOk} text={"Update"} />
          ]}
          width={750}
          >                
          <div className="row mt-4 mb-2">
              <div className="col-md-8">                        
                  <table className='table table-bordered table-striped'>
                      <tbody>
                          <tr>
                              <th>Battery Code:</th>
                              <td>{editTicketDetails?.battery?.batteryBoxSerialNumber}</td>
                          </tr>
                          <tr>
                              <th>Driver Name:</th>
                              <td>{editTicketDetails?.driver?.name}</td>
                          </tr>
                          <tr>
                              <th>Issue Category:</th>
                              <td>{editTicketDetails?.category?.category}</td>
                          </tr>
                          <tr>
                              <th>Issue Details:</th>
                              <td>{editTicketDetails?.issueDetails}</td>
                          </tr>
                          <tr>
                              <th>Assined To:</th>
                              <td>{editTicketDetails?.assignedTo?.name}</td>
                          </tr>
                          <tr>
                              <th>Team Lead:</th>
                              <td>{editTicketDetails?.teamLead?.name}</td>
                          </tr>
                          <tr>
                              <th>Location:</th>
                              <td>                                        
                                  {editTicketDetails?.location?.locality}<br></br>
                                  {editTicketDetails?.location?.city}<br></br> 
                                  {editTicketDetails?.location?.state}<br></br>
                                  {editTicketDetails?.location?.pincode}
                              </td>
                          </tr>
                          <tr>
                              <th>Partner Name:</th>
                              <td>{editTicketDetails?.partner?.companyName}</td>
                          </tr>
                          <tr>
                              <th>Created On:</th>
                              <td>{dayjs(editTicketDetails?.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <div className="col-md-4">
                  <Form form={paymentForm}>
                      <div className="row mb-2">
                          <div className="col-12">
                              <label htmlFor="transactionID" className="form-label">Transection ID:</label>
                          </div>
                          <div className="col-12">
                              <Form.Item name="transactionID" label="" rules={[{ required: true, message: 'Please enter transaction ID!' }]}>
                                  <Input
                                      placeholder="transactionID"
                                      className="form-control"
                                  />
                              </Form.Item>
                          </div>
                      </div>
                      <div className="row mb-2">
                          <div className="col-12">
                              <label htmlFor="paymentDateInApp" className="form-label">Payment Date:</label>
                          </div>
                          <div className="col-12">
                              <Form.Item name="paymentDateInApp" label="" rules={[{ required: true, message: 'Please enter payment date!' }]}>
                                  <DatePicker
                                      className="form-control"
                                      format="DD/MM/YYYY"
                                      maxDate={dayjs(new Date())}
                                  />
                              </Form.Item>
                          </div>
                      </div>
                      <div className="row mb-2">
                          <div className="col-12">
                              <label htmlFor="screenshot" className="form-label">Upload Screenshot:</label>
                          </div>
                          <div className="col-12 images-block-upload">
                              <Form.Item name="screenshot" label=""rules={[{ required: true, message: 'Please upload screenshot!' }]}>
                                  <Upload {...uploadProps}>
                                      <Button icon={<UploadOutlined />}>Select File</Button>
                                  </Upload>
                              </Form.Item>  
                          </div>
                      </div>
                      <div className="row mb-2">
                          <div className="col-12">
                              <label htmlFor="remarks" className="form-label">Remarks</label>
                          </div>
                          <div className="col-12">
                              <Form.Item name="remarks" label="" rules={[{ required: true, message: 'Please enter remarks!' }]}> 
                                  <TextArea placeholder='Remarks' />
                              </Form.Item>
                          </div>
                      </div>
                  </Form>
              </div>
          </div>
      </Modal>
    </>
  );
};

export default HelpTicketDataTable;
