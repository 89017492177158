import React from 'react';
import './CustomButton.css';

const CustomButton = ({ text, onClick, key}) => {
  return (
    <button
    className="btn btn-primary"
      onClick={onClick}
     key={key}
    >
      {text}
    </button>
  );
};

export default CustomButton;
