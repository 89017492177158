import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { height } from '@fortawesome/free-solid-svg-icons/fa0';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;
 
class BarChart extends Component {
	addSymbols(e){
		var suffixes = ["", "K", "M", "B"];
		var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
		if(order > suffixes.length - 1)
			order = suffixes.length - 1;
		var suffix = suffixes[order];
		return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
	}
	render() {
		const options = {
			animationEnabled: true,
            height: 200,
            axisY:{    
                minimum: 0,
                interval: 10
            },
            dataPointWidth: 20,
			data: [{
				type: "bar",
                color: "#7EB6EC",
				dataPoints: [
					{ y:  70, label: "Bill" },
					{ y:  60, label: "Board" },
					{ y:  50, label: "App" },
					{ y:  40, label: "Site" },
					{ y:  30, label: "Landing" }
				]
			}]
		}
		
		return (
		<div>
			<CanvasJSChart options = {options} 
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}
}

export default BarChart;