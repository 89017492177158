import React from 'react';
import TeamLeadTable from '../components/Table/TeamLeadTable';

function TeamLead() {
  return (
    <div>
    <div>
          <TeamLeadTable/>
    </div></div>
  );
}

export default TeamLead;
