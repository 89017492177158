import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './preloader.css';

const PreLoader = () => {
  return (
    <div class="position-fixed w-100 h-100 preloaderBlock">
        <div class="container h-100 w-100">
            <div class="row h-100 justify-content-center align-items-center w-100">
                <div class="col-12 text-center">
                    <p><Spinner animation="border" variant="info" /></p>
                    <p class="loadingText">Processing...</p>
                </div>
            </div>
        </div>
    </div>
  );
};

export default PreLoader;