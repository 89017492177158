import React from 'react';
import DriverDataTable from '../components/Table/DriverTable';

function Driver() {
  return (
    <div>
    <div>
          <DriverDataTable/>
    </div></div>
  );
}

export default Driver;
