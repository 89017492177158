import React, { useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Table, Modal, Form, Input, message, Select, Tag, Upload, Button, Image } from 'antd';
import SubNavbar from '../Header/SubNavbar';
import { FaEdit, FaTrash, FaListUl } from 'react-icons/fa';
import { UploadOutlined } from '@ant-design/icons';
import CustomButton from '../Button/CustomButton';
import { BASE_URL } from '../Constant/config';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PreLoader from '../PreLoader/PreLoader';
import { AiOutlineAppstore } from 'react-icons/ai';
import Swal from 'sweetalert2';
dayjs.extend(customParseFormat);

let allstatusText = [];
allstatusText['recovery'] = 'Recovery';
allstatusText['active'] = 'Active';
allstatusText['inactive'] = 'Inactive';
allstatusText['pending_for_allocation'] = 'Pending For Allocation';
allstatusText['partner_inventory'] = 'Partner Inventory';
allstatusText['sold_out'] = 'Sold Out';
allstatusText['eliminated'] = 'Eliminated';
allstatusText['service'] = 'Service';

let allstatusColors = [];
allstatusColors['recovery'] = '#8A0102';
allstatusColors['inactive'] = '#8A0102';
allstatusColors['active'] = '#248B22';
allstatusColors['pending_for_allocation'] = '#02469B';
allstatusColors['service'] = '#02469B';
allstatusColors['partner_inventory'] = '#FF4500';
allstatusColors['sold_out'] = '#A9A9A9';
allstatusColors['eliminated'] = '#A9A9A9';

const TeamLeadTable = () => {
  const [modalType, setModalType] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [leaderForm] = Form.useForm();
  const [isLoader, setIsLoader] = useState(true);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [leadersData, setLeadersData] = useState([]);
  const [perPageItem, setPerPageItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [allLocations, setAllLocations] = useState([]);
  const [availablePartners, setAvailablePartners] = useState([]);
  const [currentPartners, setCurrentPartners] = useState([]);
  const [currentUserDetails, setCurrentUserDetails] = useState({});
  const { Option } = Select;

  const [filters, setFilters] = useState({
    TL: "",
    Name: "",
    Mobile: "",
    Joining: "",
    Partner: "",
    Location: "",
    Batteries: "",
    Status: "",
    action: "",
    pincode: "",
    state: "",
    city: "",
    address: "",
    aadhaarNumber: "",
    panNumber: "",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    profilePictureURL:""
  });

  useEffect(() => {
    fetchData(currentPage, perPageItem);
    getAllLocations();
  }, []);

  const fetchData = async (page, pageSize) => {
    setIsLoader(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/leaders?limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        const data = result.data.map((item, index) => ({
          key: item.user,
          _id: item._id,
          rawData: item,
          TL:item.uniqueCode? item.uniqueCode:"-",
          Name: item.name ? item.name : "-",
          Mobile: item.mobile,
          Joining: new Date(item.createdAt).toLocaleDateString(),
          Vehicle: item.vehicleRC_p,
          Associate: item.name ? item.name : '-',
          Partner: item.partners.length > 0 ? item.partners.map(p => p.companyName).join(', ') : '-',
          Location: item.location ? `${item.location.locality || '-'}, ${item.location.city || ''}, ${item.location.state || ''}, ${item.location.pincode || ''}` : 'Location data not available',
          Batteries: item.batteries,
          Status: item.status,
          action: "Action",
          pincode: item.pincode,
          state: item.state,
          city: item.city,
          address: item.address,
          aadhaarNumber: item.aadhaarNumber,
          panNumber: item.panNumber,
          bankName: item.bankName,
          accountNumber: item.accountNumber,
          ifscCode: item.ifscCode,
          profilePictureURL:item.profilePictureURL
        }));
        setDataSource(data);
        setTotalItems(result.total);
      } else {
        console.error('Failed to fetch data:', result.message);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.error('Error fetching data:', error);
    }
  };

  const getAllLocations = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/location/get-locations?limit=50000&page=1`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data.locations)) {
        const locationsList = result.data.locations.map(location => ({
          _id: location._id,
          title: location.locality ? location.locality:"-"+"|"+location.city ? location.city:"-"+"|"+location.state ? location.state:"-"+"|"+location.pincode ? location.pincode:"-",
        }));
        setAllLocations(locationsList);
        //console.log(result.data.locations);
      } else {
        console.error('Unexpected data format:', result);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const getAvailablePartnersBasedOnLocation = async (locationId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/get-available-partners/${locationId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data)) {
        const partenersList = result.data.map(partner => ({
          _id: partner._id,
          title: partner?.companyName,
        }));
        setAvailablePartners(partenersList);
      } else {
        console.error('Unexpected data format:', result);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const handleEdit = async (event, record) => {
    event.preventDefault();
    setIsLoader(true);
    leaderForm.resetFields();
    try {
      const token = localStorage.getItem('token');
      const userId = record._id;
      const response = await fetch(`${BASE_URL}/leader/get-leader/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setCurrentUserDetails(result.data);
        setSelectedLocation(result.data?.leader?.location?._id);
        getAvailablePartnersBasedOnLocation(result.data?.leader?.location?._id);
        setCurrentPartners(result.data?.leader?.partners);
        showModal('edit', record);
      } else {
        console.error('Failed to fetch data:', result.message);
      }
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      console.error('Error fetching data:', error);
    }
    leaderForm.setFieldsValue({ 
      aadhaarNumber: record.rawData.aadhaarNumber,
      location: record.rawData.location,
      mobile: record.rawData.mobile,
      name: record.rawData.name,
      address: record.rawData.address,
      city: record.rawData.city,
      state: record.rawData.state,
      pincode: record.rawData.pincode,
      partners: record.rawData?.partners.map(p => p._id),
      location: record.rawData.location?._id,
      panNumber: record.rawData.panNumber,
      bankName: record.rawData.bankName,
      accountNumber: record.rawData.accountNumber,
      ifscCode: record.rawData.ifscCode,
      status: record.Status
    });
  };

  const handleDelete = async (event, record) => {
    event.preventDefault();
    let deleteUsers = { users: [] };
    if(record?._id){
      deleteUsers.users = [record.rawData.user];
    } else {      
      if(selectedRowKeys.length !== 0){
        deleteUsers.users = selectedRowKeys;
      }
    }    
    if(deleteUsers.users.length > 0) {
      Swal.fire({
        title: "Are you sure? ",
        html: "You want to delete <br/>You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
            confirmButton: "btn btn-success mx-1",
            cancelButton: "btn btn-danger mx-1"
        },
        buttonsStyling: !1,
      }).then(async function (t) {
          if(t.isConfirmed === true){
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/admin/delete-user/`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(deleteUsers),
            });
            const result = await response.json();
            if(result.statusCode === 200){
              message.success(result.message);
              fetchData(currentPage, perPageItem);
            } else {
              message.error('Error');
            }
          }
      });
    }
  };

  const handleAddClick = () => {
    setCurrentPartners([]);
    setCurrentUserDetails({});
    leaderForm.resetFields();
    showModal('add');
  };

  const showModal = (type, record = null) => {
    setModalType(type);
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (modalType === 'add') {
      const validationResult = leaderForm.validateFields(['name', 'mobile', 'location', 'pincode', 'state', 'city', 'address', 'aadhaarNumber', 'panNumber', 'bankName', 'accountNumber', 'ifscCode', 'status', 'panURL', 'aadhaarURL', 'cancelledChequeURL', 'profilePictureURL']).then(async (values) => {
        try {
          const token = localStorage.getItem('token');
          const createFormData = leaderForm.getFieldsValue(true); 
          const FormData = require('form-data');
          const formData = new FormData();
          for (const key in createFormData) {
            formData.append(key, createFormData[key]);
          }      
          if(createFormData.partners){
            formData.delete("partners");
            createFormData.partners.forEach((item, index) => {
              formData.append('partners[]', item);
            });
          }   
          createFormData['panURL'] ? createFormData['panURL'].fileList ? formData.append('panURL', createFormData['panURL'].fileList[0].originFileObj) : formData.delete("panURL") : console.log('panURL');
          createFormData['aadhaarURL'] ? createFormData['aadhaarURL'].fileList ? formData.append('aadhaarURL', createFormData['aadhaarURL'].fileList[0].originFileObj) : formData.delete("aadhaarURL") : console.log('aadhaarURL');
          createFormData['cancelledChequeURL'] ? createFormData['cancelledChequeURL'].fileList ? formData.append('cancelledChequeURL', createFormData['cancelledChequeURL'].fileList[0].originFileObj) : formData.delete("cancelledChequeURL") : console.log('cancelledChequeURL');
          createFormData['profilePictureURL'] ? createFormData['profilePictureURL'].fileList ? formData.append('profilePictureURL', createFormData['profilePictureURL'].fileList[0].originFileObj) : formData.delete("profilePictureURL") : console.log('profilePictureURL');
          const response = await fetch(`${BASE_URL}/admin/create-leader`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success('TL created successfully');
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
            setAvailablePartners([]);
            leaderForm.resetFields();
          } else {
            message.error('Failed to create TL - ' + result.message);
          }
        } catch (error) {
          message.error('Error creating TL', error);
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    } else {
      const validationResult = leaderForm.validateFields(['name', 'mobile', 'location', 'pincode', 'state', 'city', 'address', 'aadhaarNumber', 'panNumber', 'bankName', 'accountNumber', 'ifscCode', 'status']).then(async (values) => {
        try {
          console.log(currentRecord);
          const token = localStorage.getItem('token');
          const createFormData = leaderForm.getFieldsValue(true);
          const FormData = require('form-data');
          const formData = new FormData();
          for (const key in createFormData) {
            formData.append(key, createFormData[key]);
          }       
          if(createFormData.partners){
            formData.delete("partners");
            createFormData.partners.forEach((item, index) => {
              formData.append('partners[]', item);
            });
          }   
          createFormData['panURL'] ? createFormData['panURL'].fileList ? formData.append('panURL', createFormData['panURL'].fileList[0].originFileObj) : formData.delete("panURL") : console.log('panURL');
          createFormData['aadhaarURL'] ? createFormData['aadhaarURL'].fileList ? formData.append('aadhaarURL', createFormData['aadhaarURL'].fileList[0].originFileObj) : formData.delete("aadhaarURL") : console.log('aadhaarURL');
          createFormData['cancelledChequeURL'] ? createFormData['cancelledChequeURL'].fileList ? formData.append('cancelledChequeURL', createFormData['cancelledChequeURL'].fileList[0].originFileObj) : formData.delete("cancelledChequeURL") : console.log('cancelledChequeURL');
          createFormData['profilePictureURL'] ? createFormData['profilePictureURL'].fileList ? formData.append('profilePictureURL', createFormData['profilePictureURL'].fileList[0].originFileObj) : formData.delete("profilePictureURL") : console.log('profilePictureURL');
          const partnerId = currentRecord?.rawData?._id; 
          const response = await fetch(`${BASE_URL}/leader/update-leader/${partnerId}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success('Team Lead updated successfully');
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
            setAvailablePartners([]);
            leaderForm.resetFields();
          } else {
            message.error('Failed to update TL - ' + result.message);
          }
        } catch (error) {
          message.error('Error updateing TL');
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleExportClick = async () => {
    setIsLoader(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/export-users?role=leader`,{
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      const result = await response.json();
      if(result.statusCode === 200){
        //handleDownload(result.csvLink);
        let newObjectWithLabels = [];
        newObjectWithLabels.push(result.csvLabels);
        result.csvData.forEach(element => {
            newObjectWithLabels.push(element);
        });
        const csvData = new Blob([convertToCSV(newObjectWithLabels)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `leaders.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setIsLoader(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  }

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const handleDownload = (csvLink) => {
    if (csvLink) {
      const link = document.createElement('a');
      link.href = csvLink;
      link.setAttribute('download', 'leaders.csv'); // Set the desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const Buttons = [
    { text: 'Export', onClick: handleExportClick },
    { text: 'Add', onClick: handleAddClick },
    { text: 'Delete', onClick: handleDelete },
  ];

  const filteredData = dataSource.filter(item =>
    (filters.TL === '' || item.TL.toLowerCase().includes(filters.TL.toLowerCase())) &&
    (filters.Name === '' || item.Name.toLowerCase().includes(filters.Name.toLowerCase())) &&
    (filters.Mobile === '' || item.Mobile.toLowerCase().includes(filters.Mobile.toLowerCase())) &&
    (filters.Joining === '' || item.Joining.toLowerCase().includes(filters.Joining.toLowerCase())) &&
    (filters.Partner === '' || item.Partner.toLowerCase().includes(filters.Partner.toLowerCase())) &&
    (filters.Status === '' || item.Status.toLowerCase().includes(filters.Status.toLowerCase())) &&
    (filters.address === '' || item.address.toLowerCase().includes(filters.address.toLowerCase()))
  );

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setCurrentPage(pagination.current);
    setPerPageItem(pagination.pageSize);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFilterChange = (dataIndex, value) => {
    setFilters((prev) => ({
      ...prev,
      [dataIndex]: value,
    }));
  };

  const columns = [
    {
      title: (
        <div>
          <div>TL Code</div>
          <Input
            placeholder="TL Code"
            value={filters.TL}
            onChange={(e) => handleFilterChange('TL', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'TL',
      sorter: (a, b) => a.TL.localeCompare(b.TL),
      width: 200,
    },    
    {
      title: (
        <div>
          <div>Name</div>
          <Input
            placeholder="Name"
            value={filters.Name}
            onChange={(e) => handleFilterChange('Name', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Name',
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Mobile</div>
          <Input
            placeholder="Mobile"
            value={filters.Mobile}
            onChange={(e) => handleFilterChange('Mobile', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Mobile',
      sorter: (a, b) => a.Mobile.localeCompare(b.Mobile),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Joining Date</div>
          <Input
            placeholder="Joining Date"
            value={filters.Joining}
            onChange={(e) => handleFilterChange('Joining', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Joining',
      sorter: (a, b) => a.Joining.localeCompare(b.Joining),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Associate Partner</div>
          <Input
            placeholder="Associate Partner"
            value={filters.Partner}
            onChange={(e) => handleFilterChange('Partner', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Partner',
      sorter: (a, b) => a.Partner.localeCompare(b.Partner),
      width: 200,
    },    
    {
      title: (
        <div>
      <div>Location</div>
      <Input
         placeholder="Location"
        value={filters.Location}
        onChange={(value) => handleFilterChange('Location', value)}
        style={{ marginBottom: 8, display: 'block' }}
      />
    </div>
      ),
      dataIndex: 'Location',
      sorter: (a, b) => a.Location.localeCompare(b.Location),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Batteries</div>
          <p>&nbsp;</p>
          {/* <Input
            placeholder="Batteries"
            value={filters.Batteries}
            onChange={(e) => handleFilterChange('Batteries', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'Batteries',
      //sorter: (a, b) => a.Batteries.localeCompare(b.Batteries),
      width: 200,
      render: (_, record) => (
        <>
          <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px', 'cursor': 'pointer'}}>{record.Batteries.total}</span>
          <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>=</span>
          <span style={{'background':allstatusColors['active'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.active}</span>
          {record.Batteries.recovery > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['recovery'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.recovery}</span>
            </>
          ) : ''}
          {record.Batteries.service > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['service'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.service}</span>
            </>
          ) : ''}
          {record.Batteries.pending_for_allocation > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['pending_for_allocation'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.pending_for_allocation}</span>
            </>
          ) : ''}
          {record.Batteries.partner_inventory > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['partner_inventory'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.partner_inventory}</span>
            </>
          ) : ''}
          {record.Batteries.sold_out > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['sold_out'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.sold_out}</span>
            </>
          ) : ''}
          {record.Batteries.eliminated > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['eliminated'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.eliminated}</span>
            </>
          ) : ''}
          {record.Batteries.inactive > 0 ? (
            <>
              <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
              <span style={{'background':allstatusColors['recovery'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.inactive}</span>
            </>
          ) : ''}
        </>
      )
    },
    {
      title: (
        <div>
          <div>Status</div>
          <Input
            placeholder="Status"
            value={filters.Status}
            onChange={(e) => handleFilterChange('Status', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Status',
      sorter: (a, b) => a.Status.localeCompare(b.Status),
      width: 200,      
      render(text, record) {
        return {
          props: {
            style: { 
              'background': record.Status ? allstatusColors[record.Status] : "",
              'color' : "#fff",
              'font-weight' :  "500"
            }
          },
          children: record.Status ? allstatusText[record.Status] : '-'
        };
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <span>
          <a href='#' onClick={(event) => handleEdit(event, record)}>
            <FaEdit style={{ cursor: 'pointer', marginRight: 16 }} />
          </a>
          <a href='#' onClick={(event) => handleDelete(event, record)}>
            <FaTrash style={{ cursor: 'pointer' }} />
          </a>
        </span>
      ),
    },
  ];
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    // setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  const handleChangeSelectLocation = (value) => {
    setSelectedLocation(value);
    if(value !== ''){
      getAvailablePartnersBasedOnLocation(value);
    }
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return false;
    },
    accept: 'image/*',
    maxCount: 1
  };

  return (
    <>
      {isLoader ? (
        <PreLoader/>
      ):''}
      <SubNavbar pageName="Manage Team Lead" buttons={Buttons} />
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive mt-2">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredData}
              onChange={onChange}
              scroll={{y: `calc(100vh - 280px)`}}
              pagination={{
                simple: false,
                size:"small",
                total: totalItems,
                showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                showQuickJumper: false,
                showLessItems: true,
                responsive:true,
                defaultPageSize:perPageItem,
                pageSizeOptions:[5,10,20,50,100],
                position: ['topRight'],
                onChange: (page, pageSize) => {
                  console.log(page, pageSize);
                  fetchData(page, pageSize);
                }
              }}
              bordered
            />
          </div>
        </div>
      </div>
      <Modal
        title={modalType === 'edit' ? 'Edit TL' : 'Add TL'}
        visible={isModalVisible}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[
          <CustomButton key="submit" onClick={handleOk} text={modalType === 'edit' ? 'Save changes' : 'Add'} />
        ]}
        width={750}
      >
        <Form form={leaderForm}>
          <div className="row my-2">
            <div className="col-md-6">
              <h6>General Details</h6>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="name" className="form-label">
                    Name<span className='text-danger'>*</span>:                    
                  </label>
                </div>
                <div className="col-8">
                  <Form.Item name="name" label="" rules={[{ required: true, message: 'Please enter name!' }]}>
                    <Input
                      placeholder="Name"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="Mobile" className="form-label">Mobile<span className='text-danger'>*</span>:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="mobile" label="" rules={[{ required: true, message: 'Please enter mobile!' }]}>
                    <Input
                      placeholder="Mobile"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="location" className="form-label">Location<span className='text-danger'>*</span>:</label>
                </div>
                <div className="col-8">    
                  <Form.Item name="location" label="" rules={[{ required: true, message: 'Please select location!' }]}>          
                    <Select onChange={handleChangeSelectLocation} style={{ width: '100%' }}>
                      <Option value="">--Select Location--</Option>
                      {allLocations.map(location => (
                          <Option key={location._id} value={location._id}>{location.title}</Option>
                      ))}            
                    </Select>
                  </Form.Item>
                </div>
            </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="partners" className="form-label">Partners:</label>
                </div>
                <div className="col-8">    
                  <Form.Item name="partners" label="">          
                    <Select style={{ width: '100%' }} placeholder="Select Partners" mode="multiple" allowClear>
                      {availablePartners.map(partner => (
                          <Option key={partner._id} value={partner._id}>{partner.title}</Option>
                      ))}            
                      {selectedLocation === currentUserDetails?.leader?.location?._id ?
                        currentPartners.map(partner => (
                            <Option key={partner._id} value={partner._id}>{partner.companyName}</Option>
                        ))
                      : ''}     
                    </Select>
                  </Form.Item>
                </div>
            </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="Address" className="form-label">Address<span className='text-danger'>*</span>:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="address" label="" rules={[{ required: true, message: 'Please enter address!' }]}>
                    <Input
                      placeholder="Address"
                      className="form-control"
                    />
                  </Form.Item> 
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="city" className="form-label">City<span className='text-danger'>*</span>:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="city" label="" rules={[{ required: true, message: 'Please enter city!' }]}>
                    <Input
                      placeholder="City"
                      className="form-control"
                    />
                  </Form.Item> 
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="State" className="form-label">State<span className='text-danger'>*</span>:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="state" label="" rules={[{ required: true, message: 'Please enter state!' }]}>
                    <Input
                      placeholder="State"
                      className="form-control"
                    />
                  </Form.Item>    
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="pincode" className="form-label">Pin Code<span className='text-danger'>*</span>:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="pincode" label="" rules={[{ required: true, message: 'Please enter pincode!' }]}>
                    <Input
                      placeholder="Pin Code"
                      className="form-control"
                    />
                  </Form.Item>  
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="status" className="form-label">Status<span className='text-danger'>*</span>:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="status" label="" rules={[{ required: true, message: 'Please select status!' }]}>         
                    <Select style={{ width: '100%' }} defaultValue={''} placeholder="Select Status">
                        <Option key='empty' value=''>--Select Status--</Option>
                        <Option key='active' value='active'>Active</Option>
                        <Option key='inactive' value='inactive'>Inactive</Option>
                    </Select>
                  </Form.Item>  
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="profilePictureURL" className="form-label">Upload Photo:</label>
                </div>
                <div className="col-8 images-block-upload">
                  <Form.Item name="profilePictureURL" label="" rules={[{ required: true, message: 'Please upload profile image!' }]}>
                    <Upload {...uploadProps}>
                      <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                  </Form.Item> 
                  {currentUserDetails.leader && currentUserDetails.leader.profilePictureURL ?                  
                  <Image
                    width={30}
                    height={30}
                    src={currentUserDetails.leader.profilePictureURL}
                  /> : ''}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <h6>Details</h6>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="aadhaarNumber" className="form-label">Aadhar Number<span className='text-danger'>*</span>:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="aadhaarNumber" label="" rules={[{ required: true, message: 'Please enter aadhar number!' }]}>
                      <Input
                        placeholder="Aadhar Number"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="aadhaarURL" className="form-label">Upload Aadhar:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="aadhaarURL" label="" rules={[{ required: true, message: 'Please upload aadhar image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item> 
                    {currentUserDetails.leader && currentUserDetails.leader.aadhaarURL ?                  
                    <Image
                      width={30}
                      height={30}
                      src={currentUserDetails.leader.aadhaarURL}
                    /> : ''}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="panNumber" className="form-label">PAN<span className='text-danger'>*</span>:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="panNumber" label="" rules={[{ required: true, message: 'Please enter pan number!' }]}>
                      <Input
                        placeholder="PAN"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="panURL" className="form-label">Upload PAN:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="panURL" label="" rules={[{ required: true, message: 'Please upload pan image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item> 
                    {currentUserDetails.leader && currentUserDetails.leader.panURL ?                  
                    <Image
                      width={30}
                      height={30}
                      src={currentUserDetails.leader.panURL}
                    /> : ''}
                  </div>
                </div>
              </div>                
              <div>
                <h6>Bank Details</h6>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="bankName" className="form-label">Bank Name<span className='text-danger'>*</span>:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="bankName" label="" rules={[{ required: true, message: 'Please enter bank name!' }]}>
                      <Input
                        placeholder="Bank Name"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="accountNumber" className="form-label">Account Number<span className='text-danger'>*</span>:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="accountNumber" label="" rules={[{ required: true, message: 'Please enter account number!' }]}>
                      <Input
                        placeholder="Account Number"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="ifscCode" className="form-label">IFSC Code<span className='text-danger'>*</span>:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="ifscCode" label="" rules={[{ required: true, message: 'Please enter ifsc code!' }]}>
                      <Input
                        placeholder="IFSC Code"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="cancelledChequeURL" className="form-label">Cancelled Cheque:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="cancelledChequeURL" label="" rules={[{ required: true, message: 'Please upload cancelled cheque image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item> 
                    {currentUserDetails.leader && currentUserDetails.leader.cancelledChequeURL ?                  
                    <Image
                      width={30}
                      height={30}
                      src={currentUserDetails.leader.cancelledChequeURL}
                    /> : ''}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default TeamLeadTable;