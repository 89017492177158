import React from 'react';
import ImmobilizeTable  from '../components/Table/ImmobilizeTable';

function Immobilize() {
  return (
    <div>
      <div>
        <ImmobilizeTable/>
      </div>
    </div>
  );
}

export default Immobilize;
