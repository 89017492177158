import React from 'react';
import './navbar.css';
import CustomButton from '../Button/CustomButton';

function SubNavbar({ pageName, buttons }) {
  return (
    <div className="navbar">
      <div className="navbar-left">
        <span className="page-name">{pageName}</span>
      </div>
      <div className="navbar-right">
        {buttons.map((button, index) => (
          <CustomButton
            key={index}
            text={button.text}
            onClick={button.onClick}
          />
        ))}
      </div>
    </div>
  );
}

export default SubNavbar;
