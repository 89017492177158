import React, { useState, useEffect } from 'react';
import SubNavbar from '../components/Header/SubNavbar';
import { Form, Input, message, Select, Space, Button, Table } from 'antd';

function MyProfile() {
  const { Option } = Select;
  const loginUser = JSON.parse(localStorage.getItem('loginUserData'));
  useEffect(() => {
  }, []);
  return (
    <>
      <SubNavbar pageName="My Profile" buttons={[]} />
      <div className="row">
        <div className="col-12">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <th>Name</th>
                <td>{loginUser ? loginUser.name : ''}</td>
              </tr>
              <tr>
                <th>Mobile</th>
                <td>{loginUser ? loginUser.mobile : ''}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 
    </>
  );
}
export default MyProfile;
