import React, { useState } from 'react';
import ManageCollectionTable from '../components/Table/ManageCollectionTable';

function ManageCollection() {
return (
    <div>
        <ManageCollectionTable/>
      </div>
  );
}

export default ManageCollection;
