import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import colors from '../../components/Constant/Color';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { Form, Input, Checkbox, Select, message, Table, Modal, Space, Upload, Button, DatePicker, Image } from 'antd';
import CustomButton from '../Button/CustomButton';
import SubNavbar from '../Header/SubNavbar';
import { BASE_URL } from '../Constant/config';
import PreLoader from '../PreLoader/PreLoader';
import { UploadOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const PromotionalContent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [isAllLocations, setIsAllLocations] = useState(false);
  const [promoContentForm] = Form.useForm();
  const navigate = useNavigate();
  const { Option } = Select;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [perPageItem, setPerPageItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    fetchPromoData(currentPage, perPageItem);
    getAllLocations();
  }, []);

  const fetchPromoData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/promotional-content/get-all-promotional-content?limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setDataSource(result.data);
        setTotalItems(result.totalDocuments);
      } else {
        console.error('Failed to fetch data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAllLocations = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/location/get-locations?limit=50000&page=1`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data.locations)) {
        const locationsList = result.data.locations.map(location => ({
          _id: location._id,
          title: `${location.locality ? location.locality:"-"}, ${location.city ? location.city:"-"}, ${location.state ? location.state:"-"}, ${location.pincode ? location.pincode:"-"}`,
        }));
        setAllLocations(locationsList);
      } else {
        console.error('Unexpected data format:', result);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const handleTabChange = (key, event) => {
    console.log(key);
    console.log(event);
    if (key === "promotionalContent") {
      navigate(`/settings/promotional-content`);
    } else if(key === "notifications"){
      navigate(`/settings/notifications`);
    } else if(key === "userRoles"){
      navigate(`/settings/user-roles`);
    } else if(key === "offers"){
      navigate(`/settings/offers`);
    } else if(key === "issues"){
      navigate(`/settings/issues`);
    } else if(key === "global"){
      navigate(`/settings/global-settings`);
    }
  }

  const handleSetStatusClick = (event) => {
    event.preventDefault();
    if(selectedRowKeys.length !== 0){
      Swal.fire({
        title: "Are you sure? ",
        html: "You want to update status of selected items!",
        type: "warning",
        showCancelButton: !0,
        showDenyButton: !0,
        confirmButtonColor: "#FFFFFF",
        cancelButtonColor: "#FFFFFF",
        denyButtonColor: "#FFFFFF",
        confirmButtonText: "Active",
        denyButtonText: "Inactive",
        customClass: {
            confirmButton: "btn btn-success mx-1",
            denyButton: "btn btn-info mx-1",
            cancelButton: "btn btn-danger mx-1"
        },
        buttonsStyling: !1,
      }).then(async function (t) {
          if(t.isConfirmed === true){
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/promotional-content/toggle-status/`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "ids" : selectedRowKeys,
                "status" : "active"
              }),
            });
            const result = await response.json();
            if(result.statusCode === 200){
              message.success(result.message);
              fetchPromoData(currentPage, perPageItem);
            } else {
              message.error('Error');
            }
          }
          if(t.isDenied === true){
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/promotional-content/toggle-status/`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "ids" : selectedRowKeys,
                "status" : "inactive"
              }),
            });
            const result = await response.json();
            if(result.statusCode === 200){
              message.success(result.message);
              fetchPromoData(currentPage, perPageItem);
            } else {
              message.error('Error');
            }
          }
      });
    }
  }

  const handleAddClick = () => {
    setIsEditMode(false);
    promoContentForm.resetFields();
    setIsModalVisible(true);
  }

  const handleEdit = async (event, record) => {
    event.preventDefault();
    setIsEditMode(true);
    promoContentForm.resetFields();
    setIsModalVisible(true);
    setCurrentRecord(record);
    try {
      const token = localStorage.getItem('token');
      const promoId = record._id;
      const response = await fetch(`${BASE_URL}/promotional-content/get-promotional-content/${promoId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setCurrentRecord(result.data);
        record = result.data;
      } else {
        console.error('Failed to fetch data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsAllLocations(record.appliciableToAllLocations);
    promoContentForm.setFieldsValue({ 
      title: record.title,
      description: record.description,
      link: record.link,
      consumerType: record.consumerType,
      contentType: record.contentType,
      appliciableToAllLocations: record.appliciableToAllLocations,
      locations: record.locations.map(p => p._id),
      availability: [dayjs(dayjs(record.activationDate).format('DD/MM/YYYY'), 'DD/MM/YYYY'), dayjs(dayjs(record.deActivationDate).format('DD/MM/YYYY'), 'DD/MM/YYYY')],
    });
  }

  const handleTopDeleteClick = (event, record) => {
    event.preventDefault();
    let deleteUsers = { ids: [] };
    if(record){
      deleteUsers.ids = [record._id];
    } else {    
      if(selectedRowKeys.length !== 0){
        deleteUsers.ids = selectedRowKeys;
      }
    }    
    if(deleteUsers.ids.length > 0) {
      Swal.fire({
        title: "Are you sure? ",
        html: "You want to delete <br/>You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
            confirmButton: "btn btn-success mx-1",
            cancelButton: "btn btn-danger mx-1"
        },
        buttonsStyling: !1,
      }).then(async function (t) {
          if(t.isConfirmed === true){
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/promotional-content/delete-promotional-content/`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(deleteUsers),
            });
            const result = await response.json();
            if(result.statusCode === 200){
              message.success(result.message);
              fetchPromoData(currentPage, perPageItem);
            } else {
              message.error('Error');
            }
          }
      });
    }
  }

  const handleOk = async () => {    
    if (isEditMode) {
      promoContentForm.validateFields().then(async (values) => {
        try{
          const FormData = require('form-data');
          const formData = new FormData();    
          if(values.locations){
            values.locations.forEach((item, index) => {
              formData.append('locations[]', item);
            });
          }  
          formData.append('title', values.title); 
          formData.append('description', values.description); 
          formData.append('link', values.link); 
          formData.append('consumerType', values.consumerType); 
          formData.append('contentType', values.contentType); 
          formData.append('appliciableToAllLocations', values.appliciableToAllLocations); 
          formData.append('activationDate', dayjs(values.availability[0]).format('DD/MM/YYYY')); 
          formData.append('deActivationDate', dayjs(values.availability[1]).format('DD/MM/YYYY')); 
          values.previewAssetURL ? values.previewAssetURL.fileList ? formData.append('previewAssetURL', values.previewAssetURL.fileList[0].originFileObj) : formData.delete("previewAssetURL") : console.log('previewAssetURL');
          const token = localStorage.getItem('token');
          const updateId = currentRecord._id;
          const response = await fetch(`${BASE_URL}/promotional-content/update-promotional-content/${updateId}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success(result.message);
            fetchPromoData(currentPage, perPageItem);
            promoContentForm.resetFields();
            setIsModalVisible(false);
            setCurrentRecord({});            
          } else {
            message.error('Failed to update promotional content - ' + result.message);
          }
        } catch (error) {
          message.error('Error updating promotional content');
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    } else {
      promoContentForm.validateFields().then(async (values) => {
        try{
          const FormData = require('form-data');
          const formData = new FormData();    
          if(values.locations){
            values.locations.forEach((item, index) => {
              formData.append('locations[]', item);
            });
          }  
          formData.append('title', values.title); 
          formData.append('description', values.description); 
          formData.append('link', values.link); 
          formData.append('consumerType', values.consumerType); 
          formData.append('contentType', values.contentType); 
          formData.append('appliciableToAllLocations', values.appliciableToAllLocations); 
          formData.append('activationDate', dayjs(values.availability[0]).format('DD/MM/YYYY')); 
          formData.append('deActivationDate', dayjs(values.availability[1]).format('DD/MM/YYYY')); 
          values.previewAssetURL ? values.previewAssetURL.fileList ? formData.append('previewAssetURL', values.previewAssetURL.fileList[0].originFileObj) : formData.delete("previewAssetURL") : console.log('previewAssetURL');
          const token = localStorage.getItem('token');
          const response = await fetch(`${BASE_URL}/promotional-content/create-promotional-content/`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success(result.message);
            fetchPromoData(currentPage, perPageItem);
            promoContentForm.resetFields();
            setIsModalVisible(false);
          } else {
            message.error('Failed to create promotional content - ' + result.message);
          }
        } catch (error) {
          message.error('Error creating promotional content');
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    }
  }

  const handleCancel = async () => {
    setIsModalVisible(false);
    setCurrentRecord({});      
  }

  const Buttons = [
    { text: 'Set Status', onClick: handleSetStatusClick },
    { text: 'Add', onClick: handleAddClick },
    { text: 'Delete', onClick: handleTopDeleteClick },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onChangeApplyAllLocations = (e) => {
    setIsAllLocations(e.target.checked);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: (
        <div>
          <div>Locations</div>
          {/* <Input
            placeholder="Partners Code"
            value={filters.Partners}
            onChange={(e) => handleFilterChange('Partners', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'locations',
      sorter: (a, b) => a.locations.localeCompare(b.locations),
      width: 200,
      render: (_, record) => (
        record.locations.length > 0 ? record.locations.map(p => p.locality) : 'All'
      ),
    },
    {
      title: (
        <div>
          <div>Title</div>
          {/* <Input
            placeholder="Company Name"
            value={filters.Company}
            onChange={(e) => handleFilterChange('Company', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Description</div>
          {/* <Input
            placeholder="POC Name"
            value={filters.POC}
            onChange={(e) => handleFilterChange('POC', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Link</div>
          {/* <Input
            placeholder="Mobile"
            value={filters.Mobile}
            onChange={(e) => handleFilterChange('Mobile', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'link',
      sorter: (a, b) => a.link.localeCompare(b.link),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Content Type</div>
          {/* <Input
            placeholder="Associate TL"
            value={filters.Associate}
            onChange={(e) => handleFilterChange('Associate', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'contentType',
      sorter: (a, b) => a.contentType.localeCompare(b.contentType),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Consumer Type</div>
          {/* <Input
            placeholder="Batteries"
            value={filters.Batteries}
            onChange={(e) => handleFilterChange('Batteries', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'consumerType',
      sorter: (a, b) => a.consumerType.localeCompare(b.consumerType),
      width: 200,
      render: (_, record) => (
        <span style={{'text-transform' : 'capitalize'}}>{record.consumerType}</span>
      ),
    },
    {
      title: (
        <div>
          <div>Activation Date</div>
          {/* <Input
            placeholder="Address"
            value={filters.Address}
            onChange={(e) => handleFilterChange('Address', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'activationDate',
      sorter: (a, b) => a.activationDate.localeCompare(b.activationDate),
      width: 200,
      render: (_, record) => (
        <>
          {record.activationDate ? (
            dayjs(record.activationDate).format('DD/MM/YYYY')
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: (
        <div>
          <div>Deactivation Date</div>
          {/* <Input
            placeholder="Address"
            value={filters.Address}
            onChange={(e) => handleFilterChange('Address', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'deActivationDate',
      sorter: (a, b) => a.deActivationDate.localeCompare(b.deActivationDate),
      width: 200,
      render: (_, record) => (
        <>
          {record.deActivationDate ? (
            dayjs(record.deActivationDate).format('DD/MM/YYYY')
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: (
        <div>
          <div>Status</div>
          {/* <Input
            placeholder="Address"
            value={filters.Address}
            onChange={(e) => handleFilterChange('Address', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 200,
      render: (_, record) => (
        <span style={{'text-transform' : 'capitalize'}}>{record.status}</span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      width: 120,
      render: (_, record) => (   
        <>          
          <a href="#" onClick={(event) => handleEdit(event, record)}>
            <FaEdit style={{ cursor: 'pointer', marginRight: 16 }} />
          </a>       
          <a href="#" onClick={(event) => handleTopDeleteClick(event, record)}>
            <FaTrash style={{ cursor: 'pointer' }} />
          </a>
        </>
      )
    },
  ];

  const uploadProps = {
    beforeUpload: (file) => {
      return false;
    },
    accept: 'image/*',
    maxCount: 1
  };

  return (
    <>
      <div className='col-md-12'>
        <h6 className="my-4">Manage Settings</h6>
        <Tab.Container defaultActiveKey="promotionalContent" onSelect={handleTabChange}>
          <div className='row'>
            <div className='col-md-12'>
              <Nav variant="pills" className="flex-row tabbar-top" style={{backgroundColor: colors.primary}}>
                <Nav.Item>
                  <Nav.Link eventKey="global">Global</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="issues">Issues</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="offers">Offers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="userRoles" to="/user-roles">User Roles</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="notifications">Notifications</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="promotionalContent">Promotional Content</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="custom-tab-content">
                <Tab.Pane
                eventKey="promotionalContent" style={{backgroundColor:"transparent"}}>
                  <SubNavbar pageName="Promotional Content" buttons={Buttons} />                  
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="table-responsive mt-2">
                        <Table
                          rowSelection={rowSelection}
                          columns={columns}
                          rowKey={(record) => record._id}
                          dataSource={dataSource}
                          onChange={onChange}
                          scroll={{y: `calc(100vh - 350px)`}}
                          pagination={{
                            simple: false,
                            size:"small",
                            total: totalItems,
                            showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            showQuickJumper: false,
                            showLessItems: true,
                            responsive:true,
                            defaultPageSize:perPageItem,
                            pageSizeOptions:[5,10,20,50,100],
                            position: ['topRight'],
                            onChange: (page, pageSize) => {
                              console.log(page, pageSize);
                              fetchPromoData(page, pageSize);
                            }
                          }}
                          bordered
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>      
      <Modal
        title={isEditMode ? 'Edit Promotional Content' : 'Add Promotional Content'}
        visible={isModalVisible}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[
          <CustomButton key="submit" onClick={handleOk} text={isEditMode ? 'Save changes' : 'Add'} />
        ]}
        width={750}
      >
        <Form form={promoContentForm}>
          <div className="row mt-4 mb-2">
            <div className="col-md-6">
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="title" className="form-label">Title:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="title" label="" rules={[{ required: true, message: 'Please enter title!' }]}>
                    <Input
                      placeholder="title"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="description" className="form-label">Alternate Text:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="description" label="">
                    <Input
                      placeholder="coma, join, earn"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="consumerType" className="form-label">Audience:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="consumerType" label="" rules={[{ required: true, message: 'Please select audience!'}]}>
                    <Select defaultValue="" style={{ width: '100%' }}>
                      <Option value="">--Select Audience--</Option>
                      <Option value="partner">Partner</Option>
                      <Option value="driver">Driver</Option>
                      <Option value="both">Both</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className='col-12'>
                  <Form.Item valuePropName="checked" name="appliciableToAllLocations" label="" initialValue={false}>
                    <Checkbox onChange={onChangeApplyAllLocations}>Applicable to all locations</Checkbox>
                  </Form.Item>
                </div>
              </div>
              {isAllLocations ? '' : 
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="locations" className="form-label">Locations:</label>
                  </div>
                  <div className="col-8">   
                    <Form.Item name="locations" label="" rules={[{ required: isAllLocations === false,message: 'Please select locations!'}]}>          
                      <Select style={{ width: '100%' }} placeholder="Select Locations" mode="multiple" allowClear>
                        <Option value="">--Select Locations--</Option>
                        {allLocations.map(location => (
                            <Option key={location._id} value={location._id}>{location.title}</Option>
                        ))}            
                      </Select>
                    </Form.Item>
                  </div>
                </div> 
              }
            </div>
            <div className="col-md-6">
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="link" className="form-label">Link:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="link" label="" rules={[{ required: true, message: 'Please enter link!' }]}>
                    <Input
                      placeholder="https://youtube.com/"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="contentType" className="form-label">Type:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="contentType" label="" rules={[{ required: true, message: 'Please select type!' }]}>
                    <Select defaultValue="" style={{ width: '100%' }}>
                      <Option value="">--Select Type--</Option>
                      <Option value="image">Image</Option>
                      <Option value="video">Video</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="previewAssetURL" className="form-label">Upload Image:</label>
                </div>
                <div className="col-8 images-block-upload">
                  <Space>
                    <Form.Item name="previewAssetURL" label="">
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                    </Form.Item>                   
                    {currentRecord.previewAssetURL ?                  
                      <Image
                        width={30}
                        height={30}
                        src={currentRecord.previewAssetURL}
                      /> : ''}
                    </Space>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="availability" className="form-label">Availability:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="availability" label="" rules={[{ required: true, message: 'Please select availability date range!'}]}>
                    <RangePicker format={dateFormat}/>
                  </Form.Item> 
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default PromotionalContent;
