import React from 'react';
import ManagePaymentTable from '../components/Table/ManagePaymentsTable';

function ManagePayment() {
return (
    <div>
        <ManagePaymentTable/>
      </div>
  );
}

export default ManagePayment;
