import React, { useState } from 'react';
import { Table, Modal, Form, Input, message, Select } from 'antd';
import SubNavbar from '../Header/SubNavbar';
import { FaEdit, FaTrash, FaListUl } from 'react-icons/fa';
import CustomButton from '../Button/CustomButton';
import { BASE_URL } from '../Constant/config';
import { useEffect } from 'react';
import Swal from 'sweetalert2'

const LocationDataTable = (props, ref) => {
  const [modalType, setModalType] = useState('');
  const [locationEditForm] = Form.useForm();
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [leadersData, setLeadersData] = useState([]);
  const [perPageItem, setPerPageItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { Option } = Select;
  const [fileUrl, setFileUrl] = useState('');

  const [filters, setFilters] = useState({
    Locality: '',
    city: '',
    state: '',
    pincode: '',
    servicePersonName: '',
    servicePersonMobile: ''
  });

  const [formData, setFormData] = useState({
    locality: '',
    city: '',
    state: '',
    pincode: '',
    servicePersonName: '',
    servicePersonMobile: ''
  });

  useEffect(() => {
    fetchData(currentPage, perPageItem);
  }, []);

  const fetchData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/location/get-locations?limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        const data = result.data.locations.map((item, index) => ({
          key: item._id,
          _id: item._id,
          Locality: item.locality,
          city: item.city,
          state: item.state,
          pincode: item.pincode,
          rowData: item,
          servicePersonName: (item.servicePersonName ? item.servicePersonName : '-'),
          servicePersonMobile: (item.servicePersonMobile ? item.servicePersonMobile : '-')
        }));
        setDataSource(data);
        setTotalItems(result.data.totalCount);
      } else {
        console.error('Failed to fetch data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEdit = (event, record) => {
    event.preventDefault();
    showModal('edit', record._id);
    locationEditForm.resetFields();
    locationEditForm.setFieldsValue({ 
      locality: record.rowData.locality,
      city: record.rowData.city,
      state: record.rowData.state,
      pincode: record.rowData.pincode,
      servicePersonName: record.rowData.servicePersonName,
      servicePersonMobile: record.rowData.servicePersonMobile
    });
  };

  const handleDelete = async (event, record) => {
    event.preventDefault();
    let deleteUsers = { locations: [] };
    if(record?._id){
      deleteUsers.locations = [record._id];
    } else {      
      if(selectedRowKeys.length !== 0){
        deleteUsers.locations = selectedRowKeys;
      }
    }    
    if(deleteUsers.locations.length > 0) {
      Swal.fire({
        title: "Are you sure? ",
        html: "You want to delete <br/>You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
            confirmButton: "btn btn-success mx-1",
            cancelButton: "btn btn-danger mx-1"
        },
        buttonsStyling: !1,
      }).then(async function (t) {
          if(t.isConfirmed === true){
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/location/delete-location/`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(deleteUsers),
            });
            const result = await response.json();
            if(result.statusCode === 200){
              message.success(result.message);
              fetchData(currentPage, perPageItem);
            } else {
              message.error('Error');
            }
          }
      });
    }
  };

  const handleAddClick = () => {
    locationEditForm.resetFields();
    showModal('add');
  };

  const showModal = (type, record = null) => {
    setModalType(type);
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (modalType === 'add') {
      const validationResult = locationEditForm.validateFields(['locality', 'city', 'state', 'pincode', 'servicePersonName', 'servicePersonMobile']).then(async (values) => {
        try {
          const token = localStorage.getItem('token');
          const updateFOrmData = locationEditForm.getFieldsValue(true);
          const response = await fetch(`${BASE_URL}/location/add-location`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateFOrmData),
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success('Location created successfully');
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
            setFormData({
              locality: '',
              city: '',
              state: '',
              pincode: '',
              servicePersonName: '',
              servicePersonMobile: ''
            });
          } else {
            //console.log(result.message);
            message.error('Failed to create location - ' + result.message);
          }
        } catch (error) {
          //console.log(error);
          message.error('Error creating location');
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    } else {
      const validationResult = locationEditForm.validateFields(['locality', 'city', 'state', 'pincode', 'servicePersonName', 'servicePersonMobile']).then(async (values) => {
        try {
          const token = localStorage.getItem('token');
          const locationId = currentRecord;
          const updateFOrmData = locationEditForm.getFieldsValue(true);
          const response = await fetch(`${BASE_URL}/location/update-location/${locationId}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateFOrmData),
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success("Locations updated successfully");
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
          } else {
            message.error('Failed to update location - ' + result.message);
          }
        } catch (error) {
          message.error('Error update location');
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const handleExportClick = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/location/export-location`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        let newObjectWithLabels = [];
        newObjectWithLabels.push(result.csvLabels);
        result.csvData.forEach(element => {
            newObjectWithLabels.push(element);
        });
        const csvData = new Blob([convertToCSV(newObjectWithLabels)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `locations.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleDownload = (csvLink) => {
    if (csvLink) {
      const link = document.createElement('a');
      link.href = csvLink;
      link.setAttribute('download', 'locations.csv'); // Set the desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const Buttons = [
    { text: 'Export', onClick: handleExportClick },
    { text: 'Add', onClick: handleAddClick },
    { text: 'Delete', onClick: handleDelete },
  ];
  
  const filteredData = dataSource.filter((item) =>
    Object.keys(filters).every((key) =>
      item[key]?.toString().toLowerCase().includes(filters[key].toLowerCase())
    )
  );

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setCurrentPage(pagination.current);
    setPerPageItem(pagination.pageSize);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFilterChange = (dataIndex, value) => {
    setFilters((prev) => ({
      ...prev,
      [dataIndex]: value,
    }));
  };

  const columns = [
    {
      title: (
        <div>
          <div>Locality</div>
          <Input
            placeholder="Search Locality"
            value={filters.Locality}
            onChange={(e) => handleFilterChange('Locality', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Locality',
      sorter: (a, b) => a.Locality.localeCompare(b.Locality),
    },
    {
      title: (
        <div>
          <div>City</div>
          <Input
            placeholder="Search City"
            value={filters.city}
            onChange={(e) => handleFilterChange('city', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'city',
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      title: (
        <div>
          <div>State</div>
          <Input
            placeholder="Search State"
            value={filters.state}
            onChange={(e) => handleFilterChange('state', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'state',
      sorter: (a, b) => a.state.localeCompare(b.state),
    },
    {
      title: (
        <div>
          <div>Pincode</div>
          <Input
            placeholder="Search Pincode"
            value={filters.pincode}
            onChange={(e) => handleFilterChange('pincode', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'pincode',
      sorter: (a, b) => a.pincode - b.pincode,
    },
    {
      title: (
        <div>
          <div>Service Person Name</div>
          <Input
            placeholder="Search Service Person Name"
            value={filters.servicePersonName}
            onChange={(e) => handleFilterChange('servicePersonName', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'servicePersonName',
      sorter: (a, b) => a.servicePersonName - b.servicePersonName,
    },
    {
      title: (
        <div>
          <div>Service Person Mobile</div>
          <Input
            placeholder="Search Service Person Mobile"
            value={filters.servicePersonMobile}
            onChange={(e) => handleFilterChange('servicePersonMobile', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'servicePersonMobile',
      sorter: (a, b) => a.servicePersonMobile - b.servicePersonMobile,
    },
    {
      title: 'Action',
      dataIndex: 'action',
         fixed: 'right',
           width: 100,
      render: (_, record) => (
        <span>
          <a href='#' onClick={(event) => handleEdit(event, record)}>
            <FaEdit style={{ cursor: 'pointer', marginRight: 16 }} />
          </a>
          <a href='#' onClick={(event) => handleDelete(event, record)}>
            <FaTrash style={{ cursor: 'pointer' }} />
          </a>
        </span>
      ),
    },
  ];
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  const handleChangeSelectTL = (value) => {
    setFormData(prev => ({ ...prev, teamLead: value }));
  };

  return (
    <>
      <SubNavbar pageName="Manage Locations" buttons={Buttons} />
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive mt-2">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredData}
              onChange={onChange}
              scroll={{y: `calc(100vh - 280px)`}}
              pagination={{
                simple: false,
                size:"small",
                total: totalItems,
                showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                showQuickJumper: false,
                showLessItems: true,
                responsive:true,
                defaultPageSize:perPageItem,
                pageSizeOptions:[5,10,20,50,100],
                position: ['topRight'],
                onChange: (page, pageSize) => {
                  console.log(page, pageSize);
                  fetchData(page, pageSize);
                }
              }}
              bordered
            />
          </div>
        </div>
      </div>
      <Modal
        title={modalType === 'edit' ? 'Edit Location' : 'Add Location'}
        visible={isModalVisible}
        centered
        onCancel={handleCancel}
        footer={[
          <CustomButton key="submit" onClick={handleOk} text={modalType === 'edit' ? 'Save changes' : 'Add'}/>
        ]}
      >
          <Form form={locationEditForm}>
            <div className="row mb-3">
              <div className="col-3">
                <label htmlFor="locality" className="form-label">Locality:</label>
              </div>
              <div className="col-9">
                <Form.Item name="locality" label="" rules={[{ required: true, message: 'Please enter locality!' }]}>
                  <Input
                    placeholder="Locality"
                    className="form-control"
                    onChange={handleChange}
                  />
                </Form.Item> 
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-3">
                <label htmlFor="city" className="form-label">City:</label>
              </div>
              <div className="col-9">
                <Form.Item name="city" label="" rules={[{ required: true, message: 'Please enter city!' }]}>
                  <Input
                    placeholder="City"
                    className="form-control"
                    onChange={handleChange}
                  />
                </Form.Item> 
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-3">
                <label htmlFor="state" className="form-label">State:</label>
              </div>
              <div className="col-9">
                <Form.Item name="state" label="" rules={[{ required: true, message: 'Please enter state!' }]}>
                  <Input
                    placeholder="State"
                    className="form-control"
                    onChange={handleChange}
                  />
                </Form.Item> 
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-3">
                <label htmlFor="pincode" className="form-label">Pincode:</label>
              </div>
              <div className="col-9">
                <Form.Item name="pincode" label="" rules={[{ required: true, message: 'Please enter pincode!' }]}>
                  <Input
                    placeholder="Pincode"
                    className="form-control"
                    onChange={handleChange}
                  />
                </Form.Item> 
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-3">
                <label htmlFor="servicePersonName" className="form-label">Service Person Name:</label>
              </div>
              <div className="col-9">
                <Form.Item name="servicePersonName" label="" rules={[{ required: true, message: 'Please enter service person name!' }]}>
                  <Input
                    placeholder="Service Person Name"
                    className="form-control"
                    onChange={handleChange}
                  />
                </Form.Item> 
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-3">
                <label htmlFor="servicePersonMobile" className="form-label">Service Person Mobile:</label>
              </div>
              <div className="col-9">
                <Form.Item name="servicePersonMobile" label="" rules={[{ required: true, message: 'Please enter service person mobile!' }]}>
                  <Input
                    placeholder="Service Person Mobile"
                    className="form-control"
                    onChange={handleChange}
                  />
                </Form.Item> 
              </div>
            </div>
          </Form>        
      </Modal>
    </>
  );
};

export default LocationDataTable;