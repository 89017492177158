import React, { useState } from 'react';
import { Tabs } from 'antd';
import InventoryDataTable from '../components/Table/InventoryDataTable';
import BatteryDataTable from '../components/Table/BatteryDataTable';
import BatteryBoxDataTable from '../components/Table/BatteryBoxDataTable';
import IOTDataTable from '../components/Table/IOTDataTable';

function Inventory() {
  const { TabPane } = Tabs;
  return (
    <div>    
      {/* <Tabs>
        <TabPane tab="Battery Box" key="BatteryBox">
          <BatteryBoxDataTable/>
        </TabPane>
        <TabPane tab="Battery" key="Battery">
          <BatteryDataTable/>
        </TabPane>
        <TabPane tab="IOT" key="IOT">
          <IOTDataTable/>
        </TabPane>
      </Tabs> */}
      <InventoryDataTable/>
    </div>
  );
}
export default Inventory;