import React from 'react';
import LocationDataTable from '../components/Table/LocationDataTable';

function Location() {
  return (
    <div>
      <div>
        <LocationDataTable/>
      </div>
    </div>
  );
}

export default Location;
