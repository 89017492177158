import React from 'react';
import HelpTicketDataTable from '../components/Table/HelpTicketDataTable';

function HelpTicket() {
  return (
    <div className="page-wrapper">
            <div className='row'>
              <div className='col-sm-12'>
                <HelpTicketDataTable/>
              </div>
          </div>
    </div>
  );
}

export default HelpTicket;
