import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash } from 'react-icons/fa';
import colors from '../../components/Constant/Color';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { Form, Input, Checkbox, Select, message, Table, Modal, Space, Upload, Button, DatePicker, Image } from 'antd';
import CustomButton from '../Button/CustomButton';
import SubNavbar from '../Header/SubNavbar';
import { BASE_URL } from '../Constant/config';
import PreLoader from '../PreLoader/PreLoader';
// import SearchAdd from "../SearchAdd";
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const OffersSettings = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [isAllLocations, setIsAllLocations] = useState(false);
  const [issueType, setIssueType] = useState('');
  const [issuesForm] = Form.useForm();
  const navigate = useNavigate();
  const { Option } = Select;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [perPageItem, setPerPageItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    fetchPromoData(currentPage, perPageItem);
    getAllLocations();
  }, []);

  const fetchPromoData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/offers/get-all?limit=${pageSize}&page=${page}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setDataSource(result.data);
        setTotalItems(result.totalDocuments);
      } else {
        console.error('Failed to fetch data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAllLocations = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/location/get-locations?limit=50000&page=1`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data.locations)) {
        const locationsList = result.data.locations.map(location => ({
          _id: location._id,
          title: `${location.locality ? location.locality:"-"}, ${location.city ? location.city:"-"}, ${location.state ? location.state:"-"}, ${location.pincode ? location.pincode:"-"}`,
        }));
        setAllLocations(locationsList);
      } else {
        console.error('Unexpected data format:', result);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const handleTabChange = (key, event) => {
    console.log(key);
    console.log(event);
    if (key === "promotionalContent") {
      navigate(`/settings/promotional-content`);
    } else if(key === "notifications"){
      navigate(`/settings/notifications`);
    } else if(key === "userRoles"){
      navigate(`/settings/user-roles`);
    } else if(key === "offers"){
      navigate(`/settings/offers`);
    } else if(key === "issues"){
      navigate(`/settings/issues`);
    } else if(key === "global"){
      navigate(`/settings/global-settings`);
    }
  }

  const handleSetStatusClick = (event) => {
    event.preventDefault();
    if(selectedRowKeys.length !== 0){
      Swal.fire({
        title: "Are you sure? ",
        html: "You want to update status of selected items!",
        type: "warning",
        showCancelButton: !0,
        showDenyButton: !0,
        confirmButtonColor: "#FFFFFF",
        cancelButtonColor: "#FFFFFF",
        denyButtonColor: "#FFFFFF",
        confirmButtonText: "Active",
        denyButtonText: "Inactive",
        customClass: {
            confirmButton: "btn btn-success mx-1",
            denyButton: "btn btn-info mx-1",
            cancelButton: "btn btn-danger mx-1"
        },
        buttonsStyling: !1,
      }).then(async function (t) {
          if(t.isConfirmed === true){
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/offers/toggle-status/`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "ids" : selectedRowKeys,
                "status" : "active"
              }),
            });
            const result = await response.json();
            if(result.statusCode === 200){
              message.success(result.message);
              fetchPromoData(currentPage, perPageItem);
            } else {
              message.error('Error');
            }
          }
          if(t.isDenied === true){
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/offers/toggle-status/`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "ids" : selectedRowKeys,
                "status" : "inactive"
              }),
            });
            const result = await response.json();
            if(result.statusCode === 200){
              message.success(result.message);
              fetchPromoData(currentPage, perPageItem);
            } else {
              message.error('Error');
            }
          }
      });
    }
  }

  const handleAddClick = () => { 
    setIsEditMode(false);
    issuesForm.resetFields();
    setIsModalVisible(true);
  }

  const handleEdit = async (event, record) => {    
    event.preventDefault();
    setIsEditMode(true);
    issuesForm.resetFields();
    setIsModalVisible(true);
    setCurrentRecord(record);
    setIsAllLocations(record.appliciableToAllLocations);
    setIssueType(record.type);
    issuesForm.setFieldsValue({ 
      name: record.name,
      perday_cost: record.perday_cost,
      upfront_cost: record.upfront_cost,
      security_deposit: record.security_deposit,
      buy_back_cost: record.buy_back_cost,
      appliciableToAllLocations: record.appliciableToAllLocations,
      locations: record.locations.map(p => p._id),
      availability: [dayjs(dayjs(record.startDate).format('DD/MM/YYYY'), 'DD/MM/YYYY'), dayjs(dayjs(record.endDate).format('DD/MM/YYYY'), 'DD/MM/YYYY')],
    });
  }

  const handleTopDeleteClick = (event, record) => {
    event.preventDefault();
    let deleteUsers = { ids: [] };
    if(record){
      deleteUsers.ids = [record._id];
    } else {    
      if(selectedRowKeys.length !== 0){
        deleteUsers.ids = selectedRowKeys;
      }
    }    
    if(deleteUsers.ids.length > 0) {
      Swal.fire({
        title: "Are you sure? ",
        html: "You want to delete <br/>You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
            confirmButton: "btn btn-success mx-1",
            cancelButton: "btn btn-danger mx-1"
        },
        buttonsStyling: !1,
      }).then(async function (t) {
          if(t.isConfirmed === true){
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/offers/delete`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(deleteUsers),
            });
            const result = await response.json();
            if(result.statusCode === 200){
              message.success(result.message);
              fetchPromoData(currentPage, perPageItem);
            } else {
              message.error('Error');
            }
          }
      });
    }
  }

  const handleOk = async () => {    
    if (isEditMode) {
      issuesForm.validateFields().then(async (values) => {
        try{
          const token = localStorage.getItem('token');
          const updateId = currentRecord._id;          
          values.startDate =  dayjs(values.availability[0]).format('DD/MM/YYYY'); 
          values.endDate =  dayjs(values.availability[1]).format('DD/MM/YYYY'); 
          const response = await fetch(`${BASE_URL}/offers/update/${updateId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(values),
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success(result.message);
            fetchPromoData(currentPage, perPageItem);
            issuesForm.resetFields();
            setIsModalVisible(false);
            setCurrentRecord({});            
          } else {
            message.error('Failed to update offer - ' + result.message);
          }
        } catch (error) {
          message.error('Error updating offer');
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    } else {
      issuesForm.validateFields().then(async (values) => {
        try{
          console.log(values);
          const token = localStorage.getItem('token');        
          values.startDate =  dayjs(values.availability[0]).format('DD/MM/YYYY'); 
          values.endDate =  dayjs(values.availability[1]).format('DD/MM/YYYY'); 
          const response = await fetch(`${BASE_URL}/offers/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(values),
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success(result.message);
            fetchPromoData(currentPage, perPageItem);
            issuesForm.resetFields();
            setIsModalVisible(false);
          } else {
            message.error('Failed to create offer - ' + result.message);
          }
        } catch (error) {
          message.error('Error creating offer');
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    }
  }

  const handleCancel = async () => {
    setIsModalVisible(false);
    setCurrentRecord({});      
  }

  const Buttons = [
    { text: 'Set Status', onClick: handleSetStatusClick },
    { text: 'Add', onClick: handleAddClick },
    { text: 'Delete', onClick: handleTopDeleteClick },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onChangeApplyAllLocations = (e) => {
    setIsAllLocations(e.target.checked);
  };

  const onChangeTypeOfIssue = (value) => {
    setIssueType(value);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: (
        <div>
          <div>Locations</div>
          {/* <Input
            placeholder="Partners Code"
            value={filters.Partners}
            onChange={(e) => handleFilterChange('Partners', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'locations',
      sorter: (a, b) => a.locations.localeCompare(b.locations),
      width: 150,
      render: (_, record) => (
        record.locations.length > 0 ? record.locations.map(p => <strong>{p.locality}<br></br></strong>) : (<strong>ALL</strong>)
      ),
    },
    {
      title: (
        <div>
          <div>Name</div>
          {/* <Input
            placeholder="Company Name"
            value={filters.Company}
            onChange={(e) => handleFilterChange('Company', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 200,
      render: (_, record) => (
        <span>{record.name}</span>
      ),
    },
    {
      title: (
        <div>
          <div>Per Day Cost</div>
          {/* <Input
            placeholder="POC Name"
            value={filters.POC}
            onChange={(e) => handleFilterChange('POC', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'perday_cost',
      sorter: (a, b) => a.perday_cost.localeCompare(b.perday_cost),
      width: 100,
      render: (_, record) => (
        record.perday_cost ? record.perday_cost : '-'
      ),
    },
    {
      title: (
        <div>
          <div>Upfront Cost</div>
          {/* <Input
            placeholder="Mobile"
            value={filters.Mobile}
            onChange={(e) => handleFilterChange('Mobile', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'upfront_cost',
      sorter: (a, b) => a.upfront_cost.localeCompare(b.upfront_cost),
      width: 100,
      render: (_, record) => (
        record.upfront_cost ? record.upfront_cost : '-'
      ),
    },
    {
      title: (
        <div>
          <div>Security Deposit</div>
          {/* <Input
            placeholder="Mobile"
            value={filters.Mobile}
            onChange={(e) => handleFilterChange('Mobile', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'security_deposit',
      sorter: (a, b) => a.security_deposit.localeCompare(b.security_deposit),
      width: 100,
      render: (_, record) => (
        record.security_deposit ? record.security_deposit : '-'
      ),
    },
    {
      title: (
        <div>
          <div>Buy Back Cost</div>
          {/* <Input
            placeholder="Mobile"
            value={filters.Mobile}
            onChange={(e) => handleFilterChange('Mobile', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'buy_back_cost',
      sorter: (a, b) => a.buy_back_cost.localeCompare(b.buy_back_cost),
      width: 100,
      render: (_, record) => (
        record.buy_back_cost ? record.buy_back_cost : '-'
      ),
    },
    {
      title: (
        <div>
          <div>Status</div>
          {/* <Input
            placeholder="Address"
            value={filters.Address}
            onChange={(e) => handleFilterChange('Address', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          /> */}
        </div>
      ),
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 100,
      render: (_, record) => (
        <span style={{'text-transform' : 'capitalize'}}>{record.status}</span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      width: 120,
      render: (_, record) => (   
        <>          
          <a href="#" onClick={(event) => handleEdit(event, record)}>
            <FaEdit style={{ cursor: 'pointer', marginRight: 16 }} />
          </a>       
          <a href="#" onClick={(event) => handleTopDeleteClick(event, record)}>
            <FaTrash style={{ cursor: 'pointer' }} />
          </a>
        </>
      )
    },
  ];

  const uploadProps = {
    accept: 'image/*',
    maxCount: 1
  };

  return (
    <>
      <div className='col-md-12'>
        <h6 className="my-4">Manage Settings</h6>
        <Tab.Container defaultActiveKey="offers" onSelect={handleTabChange}>
          <div className='row'>
            <div className='col-md-12'>
              <Nav variant="pills" className="flex-row tabbar-top" style={{backgroundColor: colors.primary}}>
                <Nav.Item>
                  <Nav.Link eventKey="global">Global</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="issues">Issues</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="offers">Offers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="userRoles" to="/user-roles">User Roles</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="notifications">Notifications</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="promotionalContent">Promotional Content</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="custom-tab-content">
                <Tab.Pane
                eventKey="offers" style={{backgroundColor:"transparent"}}>
                  <SubNavbar pageName="Offers" buttons={Buttons} />                  
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="table-responsive mt-2">
                        <Table
                          rowSelection={rowSelection}
                          columns={columns}
                          rowKey={(record) => record._id}
                          dataSource={dataSource}
                          onChange={onChange}
                          scroll={{y: `calc(100vh - 350px)`}}
                          pagination={{
                            simple: false,
                            size:"small",
                            total: totalItems,
                            showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            showQuickJumper: false,
                            showLessItems: true,
                            responsive:true,
                            defaultPageSize:perPageItem,
                            pageSizeOptions:[5,10,20,50,100],
                            position: ['topRight'],
                            onChange: (page, pageSize) => {
                              console.log(page, pageSize);
                              fetchPromoData(page, pageSize);
                            }
                          }}
                          bordered
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>      
      <Modal
        title={isEditMode ? 'Edit Offer' : 'Add Offer'}
        visible={isModalVisible}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[
          <CustomButton key="submit" onClick={handleOk} text={isEditMode ? 'Save changes' : 'Add'} />
        ]}
        width={750}
      >
        <Form form={issuesForm}>
          <div className="row mt-4 mb-2">
            <div className="col-md-6">
              <div className="row mb-2">
                <div className='col-12'>
                  <Form.Item valuePropName="checked" name="appliciableToAllLocations" label="" initialValue={false}>
                    <Checkbox onChange={onChangeApplyAllLocations}>Applicable to all locations</Checkbox>
                  </Form.Item>
                </div>
              </div>
              {isAllLocations ? '' : 
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="locations" className="form-label">Locations:</label>
                  </div>
                  <div className="col-8">   
                    <Form.Item name="locations" label="" rules={[{ required: isAllLocations === false,message: 'Please select locations!'}]}>          
                      <Select style={{ width: '100%' }} placeholder="Select Locations" mode="multiple" allowClear>
                        <Option value="">--Select Locations--</Option>
                        {allLocations.map(location => (
                            <Option key={location._id} value={location._id}>{location.title}</Option>
                        ))}            
                      </Select>
                    </Form.Item>
                  </div>
                </div> 
              }
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="name" className="form-label">Name:</label>
                </div>
                <div className="col-8 position-relative">
                  <Form.Item name="name" label="" rules={[{ required: true, message: 'Please enter name!'}]}>
                    <Input className='form-control'/>
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="availability" className="form-label">Availability:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="availability" label="" rules={[{ required: true, message: 'Please select availability date range!'}]}>
                    <RangePicker format={dateFormat}/>
                  </Form.Item> 
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="perday_cost" className="form-label">Per Day Cost:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="perday_cost" label="" rules={[{ required: true, message: 'Please enter Per Day Cost!' }, { pattern: /^[0-9]+$/, message: 'Input must only contain numbers' }]}>
                    <Input className='form-control' />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="upfront_cost" className="form-label">Upfront Cost:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="upfront_cost" label="" rules={[{ required: true, message: 'Please enter Upfront Cost!' }, { pattern: /^[0-9]+$/, message: 'Input must only contain numbers' }]}>
                    <Input className='form-control' />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="security_deposit" className="form-label">Security Deposit:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="security_deposit" label="" rules={[{ required: true, message: 'Please enter Security Deposit!' }, { pattern: /^[0-9]+$/, message: 'Input must only contain numbers' }]}>
                    <Input className='form-control' />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="buy_back_cost" className="form-label">Buy Back Cost:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="buy_back_cost" label="" rules={[{ required: true, message: 'Please enter Buy Back Cost!' }, { pattern: /^[0-9]+$/, message: 'Input must only contain numbers' }]}>
                    <Input className='form-control' />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default OffersSettings;
