import React, { useState } from 'react';
import { Table, Modal, Form, Input, message, Select, Tag, Image, Space, Upload, Button } from 'antd';
import SubNavbar from '../Header/SubNavbar';
import { FaEdit, FaTrash, FaListUl, FaSave, FaCheckSquare, FaMapMarker, FaFile } from 'react-icons/fa';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import CustomButton from '../Button/CustomButton';
import { UploadOutlined } from '@ant-design/icons';
import { BASE_URL } from '../Constant/config';
import { useEffect } from 'react';
import Swal from 'sweetalert2'
import TextArea from 'antd/es/input/TextArea';
import PreLoader from '../PreLoader/PreLoader';

let allstatusText = [];
allstatusText['active'] = 'Active';
allstatusText['inactive'] = 'Inactive';
allstatusText['kyc_pending'] = 'KYC Pending';
allstatusText['kyc_rejected'] = 'KYC Rejected';
allstatusText['kyc_verified'] = 'KYC Approved';
let allstatusColors = [];
allstatusColors['active'] = '#238B21';
allstatusColors['inactive'] = '#A9A9A9';
allstatusColors['kyc_pending'] = '#FF4500';
allstatusColors['kyc_rejected'] = '#8B0000';
allstatusColors['kyc_verified'] = '#00469A';

const DriverDataTable = () => {
  const gMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const [modalType, setModalType] = useState('');
  const [driverLocationCord, setDriverLocationCord] = useState({});
  const [mapMarkers, setMapMarkers] = useState([]);
  const [mapLocationType, setMapLocationType] = useState('');
  const [driverForm] = Form.useForm();
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isKYCModalVisible, setIsKYCModalVisible] = useState(false);
  const [isFilesModalVisible, setIsFilesModalVisible] = useState(false);
  const [isLocationModalVisible, setIsLocationModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [perPageItem, setPerPageItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [allLocations, setAllLocations] = useState([]);
  const [associatedLeaders, setAssociatedLeaders] = useState([]);
  const [associatedPartners, setAssociatedPartners] = useState([]);
  const [currentPartners, setCurrentPartners] = useState([]);
  const [currentLeaderOnDriver, setCurrentLeaderOnDriver] = useState([]);
  const [currentDriverDetails, setCurrentDriverDetails] = useState({});
  const [editDriverDetails, setEditDriverDetails] = useState({});
  const { Option } = Select;

  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const [filters, setFilters] = useState({
    Driver: "",
    Name: "",
    Mobile: "",
    Joining: "",
    Vehicle: '',
    Associate: "",
    Partner: "",
    Location: "",
    Batteries: "",
    Status: "",
  });

  useEffect(() => {
    fetchData(currentPage, perPageItem);
    getAllLocations();
  }, []);  

  const fetchData = async (page, pageSize) => {
    setIsLoader(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/get-users?role=driver&limit=${pageSize}&page=${page}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        const data = result.data.map((item, index) => ({
          key: index,
          _id: item._id,
          rawData: item,
          Driver: item.uniqueCode ? item.uniqueCode:"-",
          Name: item.name ? item.name : "-",
          Mobile: item.mobile,
          Joining: new Date(item.createdAt).toLocaleDateString(),
          Vehicle: item.vehicleRC_p,
          Associate: item.teamLead ? item.teamLead.name : '-',
          Partner: item.partner ? item.partner.name : '-',
          Location: item.localAddress ? item.localAddress: "-",
          Batteries: item.batteries ? item.batteries : "-",
          Status: item.status,
          action: "Action"
        }));
        setDataSource(data);
        setTotalItems(result.totalCount);
        setIsLoader(false);
      } else {
        console.error('Failed to fetch data:', result.message);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  };

  const getAllLocations = async () => {
    try {
      setIsLoader(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/location/get-locations?limit=50000&page=1`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data.locations)) {
        const locationsList = result.data.locations.map(location => ({
          _id: location._id,
          title: location.locality ? location.locality:"-"+"|"+location.city ? location.city:"-"+"|"+location.state ? location.state:"-"+"|"+location.pincode ? location.pincode:"-",
        }));
        setAllLocations(locationsList);
        setIsLoader(false);
        //console.log(result.data.locations);
      } else {
        console.error('Unexpected data format:', result);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
      setIsLoader(false);
    }
  };

  const getAllLeadersByLocation = async (locationId) => {
    try {
      setIsLoader(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/get-leaders-based-on-location/${locationId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data)) {
        setAssociatedLeaders(result.data);
        console.log(result.data);
        setIsLoader(false);
      } else {
        console.error('Unexpected data format:', result);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching leaders:', error);
      setIsLoader(false);
    }
  };

  const getAllPartnersByLeader = async (leaderId) => {
    try {
      setIsLoader(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/get-partners-based-on-leader/${leaderId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && Array.isArray(result.data)) {
        setAssociatedPartners(result.data);
        setIsLoader(false);
      } else {
        console.error('Unexpected data format:', result);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
      setIsLoader(false);
    }
  };

  const handleEdit = async (event, record) => {
    event.preventDefault();   
    setIsLoader(true); 
    driverForm.resetFields();
    try {
      const token = localStorage.getItem('token');
      const userId = record._id;
      const response = await fetch(`${BASE_URL}/driver/get-driver/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setEditDriverDetails(result.data);
        setIsLoader(false);
      } else {
        console.error('Failed to fetch data:', result.message);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
    showModal('edit', record._id);
    let partnerExist = record.rawData.partner !== null ? [record.rawData.partner] : [];
    let LeaderExist = record.rawData.teamLead !== null ? [record.rawData.teamLead] : [];
    setCurrentPartners(partnerExist);
    setCurrentLeaderOnDriver(LeaderExist);
    driverForm.setFieldsValue({ 
      name: record.rawData?.name,
      mobile: record.rawData?.mobile,
      localAddress: record.rawData?.localAddress,
      alternateMobile: record.rawData?.alternateMobile,
      panNumber_p: record.rawData?.panNumber_p,
      vehicleRC_p: record.rawData?.vehicleRC_p,
      vehicleChassisNumber_p: record.rawData?.vehicleChassisNumber_p,
      aadhaarNumber_p: record.rawData?.aadhaarNumber_p,
      partner: record.rawData.partner ? record.rawData.partner.id ? record.rawData.partner.id : '' : '',
      location: record.rawData.location ? record.rawData.location : '',
      leader: record.rawData.teamLead ? record.rawData.teamLead.id ? record.rawData.teamLead.id : '' : ''
    });
  };

  const handleDelete = async (event, record) => {
    event.preventDefault();
    let deleteUsers = { users: [] };
    if(record?._id){
      deleteUsers.users = [record.rawData.user];
    } else {      
      if(selectedRowKeys.length !== 0){
        deleteUsers.users = selectedRowKeys;
      }
    }    
    if(deleteUsers.users.length > 0) {
      Swal.fire({
        title: "Are you sure? ",
        html: "You want to delete <br/>You won't be able to revert this!",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
            confirmButton: "btn btn-success mx-1",
            cancelButton: "btn btn-danger mx-1"
        },
        buttonsStyling: !1,
      }).then(async function (t) {
          if(t.isConfirmed === true){
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/admin/delete-user/`, {
              method: 'PUT',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(deleteUsers),
            });
            const result = await response.json();
            if(result.statusCode === 200){
              message.success(result.message);
              fetchData(currentPage, perPageItem);
            } else {
              message.error('Error');
            }
          }
      });
    }
  };

  const handleLocationDetsils = async (event, record) => {
    event.preventDefault();    
    setCurrentRecord(record);
    Swal.fire({
      title: "Choose Location",
      html: "",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Driver Location",
      cancelButtonText: "IOT Location",
      customClass: {
          confirmButton: "btn btn-success mx-1",
          cancelButton: "btn btn-danger mx-1"
      },
      buttonsStyling: !1,
    }).then(async function (t) {
        if(t.isConfirmed === true){
          setMapLocationType('Driver Location');
          try { 
            setIsLoader(true);
            const token = localStorage.getItem('token');
            const currentUserId = record._id;
            const response = await fetch(`${BASE_URL}/admin/get-driver-coords?driverId=${currentUserId}&locationType=driver`, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });
            const result = await response.json();
            if(result.data){
              setDriverLocationCord({
                lat: result.data.latitude, // default latitude
                lng: result.data.longitude, // default longitude
              });
              setIsLocationModalVisible(true);
            }
            setIsLoader(false);
          } catch (error) {
            console.error('Error fetching locations:', error);
            setIsLoader(false);
          }
        } else if(t.isDismissed === true) {
          setMapLocationType('Driver IOT Location');
          try { 
            setIsLoader(true);
            const token = localStorage.getItem('token');
            const currentUserId = record._id;
            const response = await fetch(`${BASE_URL}/admin/get-driver-coords?driverId=${currentUserId}&locationType=iot`, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });
            const result = await response.json();
            setMapMarkers(result.data);
            setIsLocationModalVisible(true);
            setIsLoader(false);
          } catch (error) {
            console.error('Error fetching locations:', error);
            setIsLoader(false);
          }
        }
    });
  }

  const handleGetAllFiles = async (event, record) => {
    event.preventDefault(); 
    setIsLoader(true); 
    try {
      const token = localStorage.getItem('token');
      const userId = record._id;
      const response = await fetch(`${BASE_URL}/driver/get-driver/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setCurrentDriverDetails(result.data);
        setIsFilesModalVisible(true);
        setIsLoader(false);
      } else {
        console.error('Failed to fetch data:', result.message);
        setIsLoader(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    } 
  }

  const handleVerifyUser = async (event, record) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const userId = record.rawData.referralID;
      const response = await fetch(`${BASE_URL}/admin/get-driver-referral/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200) {
        setCurrentDriverDetails(result.data);
        setIsKYCModalVisible(true);
      } else {
        console.error('Failed to fetch data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleAddClick = () => {
    setCurrentPartners([]);
    setEditDriverDetails({});
    driverForm.resetFields();
    showModal('add');
  };

  const showModal = (type, record = null) => {
    setModalType(type);
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (modalType === 'add') {
      const validationResult = driverForm.validateFields(['name', 'mobile', 'localAddress', 'alternateMobile', 'panNumber_p', 'vehicleRC_p', 'vehicleChassisNumber_p', 'aadhaarNumber_p', 'partner', 'panURL_p', 'aadhaarURL_p', 'cibilURL_p', 'vehicleWithDriverURL_p', 'vehicleChassisURL_p', 'vehicleRCURL_p', 'profilePictureURL']).then(async (values) => {
        try {
          setIsLoader(true);
          const token = localStorage.getItem('token');
          const createFormData = driverForm.getFieldsValue(true);   
          const FormData = require('form-data');
          const formData = new FormData();
          for (const key in createFormData) {
            formData.append(key, createFormData[key]);
          } 
          createFormData['panURL_p'] ? createFormData['panURL_p'].fileList ? formData.append('panURL_p', createFormData['panURL_p'].fileList[0].originFileObj) : formData.delete("panURL_p") : console.log('panURL_p');
          createFormData['aadhaarURL_p'] ? createFormData['aadhaarURL_p'].fileList ? formData.append('aadhaarURL_p', createFormData['aadhaarURL_p'].fileList[0].originFileObj) : formData.delete("aadhaarURL_p") : console.log('aadhaarURL_p');
          createFormData['cibilURL_p'] ? createFormData['cibilURL_p'].fileList ? formData.append('cibilURL_p', createFormData['cibilURL_p'].fileList[0].originFileObj) : formData.delete("cibilURL_p") : console.log('cibilURL_p');
          createFormData['vehicleWithDriverURL_p'] ? createFormData['vehicleWithDriverURL_p'].fileList ? formData.append('vehicleWithDriverURL_p', createFormData['vehicleWithDriverURL_p'].fileList[0].originFileObj) : formData.delete("vehicleWithDriverURL_p") : console.log('vehicleWithDriverURL_p');
          createFormData['vehicleChassisURL_p'] ? createFormData['vehicleChassisURL_p'].fileList ? formData.append('vehicleChassisURL_p', createFormData['vehicleChassisURL_p'].fileList[0].originFileObj) : formData.delete("vehicleChassisURL_p") : console.log('vehicleChassisURL_p');
          createFormData['vehicleRCURL_p'] ? createFormData['vehicleRCURL_p'].fileList ? formData.append('vehicleRCURL_p', createFormData['vehicleRCURL_p'].fileList[0].originFileObj) : formData.delete("vehicleRCURL_p") : console.log('vehicleRCURL_p');
          createFormData['profilePictureURL'] ? createFormData['profilePictureURL'].fileList ? formData.append('profilePictureURL', createFormData['profilePictureURL'].fileList[0].originFileObj) : formData.delete("profilePictureURL") : console.log('profilePictureURL');
          const response = await fetch(`${BASE_URL}/admin/create-driver`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success('Driver created successfully');
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
            driverForm.resetFields();
            setAssociatedLeaders([]);
            setAssociatedPartners([]);
            setIsLoader(false);
          } else {
            message.error('Failed to create driver - ' + result.message);
            setIsLoader(false);
          }
        } catch (error) {
          message.error('Error creating driver');
          setIsLoader(false);
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    } else {
      const validationResult = driverForm.validateFields(['name', 'mobile', 'localAddress', 'alternateMobile', 'panNumber_p', 'vehicleRC_p', 'vehicleChassisNumber_p', 'aadhaarNumber_p', 'partner']).then(async (values) => {
        try {
          setIsLoader(true);
          const token = localStorage.getItem('token');
          const createFormData = driverForm.getFieldsValue(true);  
          const FormData = require('form-data');
          const formData = new FormData();
          for (const key in createFormData) {
            formData.append(key, createFormData[key]);
          } 
          createFormData['panURL_p'] ? createFormData['panURL_p'].fileList ? formData.append('panURL_p', createFormData['panURL_p'].fileList[0].originFileObj) : formData.delete("panURL_p") : console.log('panURL_p');
          createFormData['aadhaarURL_p'] ? createFormData['aadhaarURL_p'].fileList ? formData.append('aadhaarURL_p', createFormData['aadhaarURL_p'].fileList[0].originFileObj) : formData.delete("aadhaarURL_p") : console.log('aadhaarURL_p');
          createFormData['cibilURL_p'] ? createFormData['cibilURL_p'].fileList ? formData.append('cibilURL_p', createFormData['cibilURL_p'].fileList[0].originFileObj) : formData.delete("cibilURL_p") : console.log('cibilURL_p');
          createFormData['vehicleWithDriverURL_p'] ? createFormData['vehicleWithDriverURL_p'].fileList ? formData.append('vehicleWithDriverURL_p', createFormData['vehicleWithDriverURL_p'].fileList[0].originFileObj) : formData.delete("vehicleWithDriverURL_p") : console.log('vehicleWithDriverURL_p');
          createFormData['vehicleChassisURL_p'] ? createFormData['vehicleChassisURL_p'].fileList ? formData.append('vehicleChassisURL_p', createFormData['vehicleChassisURL_p'].fileList[0].originFileObj) : formData.delete("vehicleChassisURL_p") : console.log('vehicleChassisURL_p');
          createFormData['vehicleRCURL_p'] ? createFormData['vehicleRCURL_p'].fileList ? formData.append('vehicleRCURL_p', createFormData['vehicleRCURL_p'].fileList[0].originFileObj) : formData.delete("vehicleRCURL_p") : console.log('vehicleRCURL_p');
          createFormData['profilePictureURL'] ? createFormData['profilePictureURL'].fileList ? formData.append('profilePictureURL', createFormData['profilePictureURL'].fileList[0].originFileObj) : formData.delete("profilePictureURL") : console.log('profilePictureURL');
          const partnerId = currentRecord; 
          const response = await fetch(`${BASE_URL}/driver/update-driver/${partnerId}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
            body: formData,
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success('Driver updated successfully');
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
            driverForm.resetFields();
            setAssociatedLeaders([]);
            setAssociatedPartners([]);
            setIsLoader(false);
          } else {
            message.error('Failed to update driver - ' + result.message);
            setIsLoader(false);
          }
        } catch (error) {
          message.error('Error updateing driver');
          setIsLoader(false);
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsKYCModalVisible(false);
    setIsFilesModalVisible(false);
    setIsLocationModalVisible(false);
  };

  const handleKYCApprove = async () => {
    console.log('handleKYCApprove');   
    try {
      const token = localStorage.getItem('token');
      const userId = currentDriverDetails._id; 
      const response = await fetch(`${BASE_URL}/admin/approve-driver-referral/${userId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200 || result.statusCode === 201) {
        message.success('Driver approved successfully');
        fetchData(currentPage, perPageItem);
        handleCancel();
      } else {
        message.error('Failed to approve driver - ' + result.message);
      }
    } catch (error) {
      message.error('Error approve driver');
    }
  };

  const handleKYCReject = async () => {
    console.log('handleKYCReject');  
    try {
      const token = localStorage.getItem('token');
      const userId = currentDriverDetails._id; 
      const response = await fetch(`${BASE_URL}/admin/reject-driver-referral/${userId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result.statusCode === 200 || result.statusCode === 201) {
        message.success('Driver rejected successfully');
        fetchData(currentPage, perPageItem);
        handleCancel();
      } else {
        message.error('Failed to rejected driver - ' + result.message);
      }
    } catch (error) {
      message.error('Error rejected driver');
    }
  };

  const handleExportClick = async () => {
    setIsLoader(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/admin/export-users?role=driver`,{
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      const result = await response.json();
      if(result.statusCode === 200){
        // handleDownload(result.csvLink);
        let newObjectWithLabels = [];
        newObjectWithLabels.push(result.csvLabels);
        result.csvData.forEach(element => {
            newObjectWithLabels.push(element);
        });
        const csvData = new Blob([convertToCSV(newObjectWithLabels)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `drivers.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setIsLoader(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoader(false);
    }
  }

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const handleDownload = (csvLink) => {
    if (csvLink) {
      const link = document.createElement('a');
      link.href = csvLink;
      link.setAttribute('download', 'drivers.csv'); // Set the desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const Buttons = [
    { text: 'Export', onClick: handleExportClick },
    { text: 'Add', onClick: handleAddClick },
    { text: 'Delete', onClick: handleDelete },
  ];

  const filteredData = dataSource.filter(item =>
    (filters.Driver === '' || item.Driver.toLowerCase().includes(filters.Driver.toLowerCase())) &&
    (filters.Name === '' || item.Name.toLowerCase().includes(filters.Name.toLowerCase())) &&
    (filters.Vehicle === '' || item.Vehicle.toLowerCase().includes(filters.Vehicle.toLowerCase())) &&
    (filters.Mobile === '' || (item?.Mobile?.toString() || '').toLowerCase().includes(filters.Mobile.toLowerCase())) &&
    (filters.Batteries === '' || (item?.Batteries?.toString() || '').toLowerCase().includes(filters.Batteries.toLowerCase())) &&
    (filters.Associate === '' || item.Associate.toLowerCase().includes(filters.Associate.toLowerCase())) &&
    (filters.Partner === '' || item.Partner.toLowerCase().includes(filters.Partner.toLowerCase())) &&
    (filters.Location === '' || item.Location.toLowerCase().includes(filters.Location.toLowerCase())) &&
    (filters.Status === '' || item.Status.toLowerCase().includes(filters.Status.toLowerCase())) &&
    (filters.Joining === '' || item.Joining.toLowerCase().includes(filters.Joining.toLowerCase()))
  );

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    setCurrentPage(pagination.current);
    setPerPageItem(pagination.pageSize);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFilterChange = (dataIndex, value) => {
    setFilters((prev) => ({
      ...prev,
      [dataIndex]: value,
    }));
  };

  const columns = [
    {
      title: (
        <div>
          <div>Driver Code</div>
          <Input
            placeholder="Driver Code"
            value={filters.Driver}
            onChange={(e) => handleFilterChange('Driver', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Driver',
      sorter: (a, b) => a.Driver.localeCompare(b.Driver),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Name</div>
          <Input
            placeholder="Name"
            value={filters.Name}
            onChange={(e) => handleFilterChange('Name', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Name',
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Mobile</div>
          <Input
            placeholder="Mobile"
            value={filters.Mobile}
            onChange={(e) => handleFilterChange('Mobile', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Mobile',
      sorter: (a, b) => (a?.Mobile?.toString() || '').localeCompare(b?.Mobile?.toString() || ''),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Joining Date</div>
          <Input
            placeholder="Joining Date"
            value={filters.Joining}
            onChange={(e) => handleFilterChange('Joining', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Joining',
      sorter: (a, b) => a.Joining.localeCompare(b.Joining),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Vehicle Type</div>
          <Input
            placeholder="Vehicle Type"
            value={filters.Vehicle}
            onChange={(e) => handleFilterChange('Vehicle', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Vehicle',
      sorter: (a, b) => a.Vehicle.localeCompare(b.Vehicle),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Associate TL</div>
          <Input
            placeholder="Associate TL"
            value={filters.Associate}
            onChange={(e) => handleFilterChange('Associate', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Associate',
      sorter: (a, b) => a.Associate.localeCompare(b.Associate),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Associate Partner</div>
          <Input
            placeholder="Associate Partner"
            value={filters.Partner}
            onChange={(e) => handleFilterChange('Partner', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Partner',
      sorter: (a, b) => a.Partner.localeCompare(b.Partner),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Location</div>
          <Input
            placeholder="Location"
            value={filters.Location}
            onChange={(e) => handleFilterChange('Location', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Location',
      sorter: (a, b) => a.Location.localeCompare(b.Location),
      width: 200,
    },
    {
      title: (
        <div>
          <div>Batteries</div>
          <Input
            placeholder="Batteries"
            value={filters.Batteries}
            onChange={(e) => handleFilterChange('Batteries', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Batteries',
      sorter: (a, b) => (a?.Batteries?.toString() || '').localeCompare(b?.Batteries?.toString() || ''),
      width: 200,     
      render: (_, record) => (
        <>
          <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>{record.Batteries.total}</span>
          <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>=</span>
          <span style={{'background':allstatusColors['active'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.active}</span>
          <span style={{'background':'none', 'padding': '5px 0', 'margin-right': '5px'}}>+</span>
          <span style={{'background':allstatusColors['kyc_rejected'], 'color': '#fff', 'padding': '5px', 'margin-right': '5px'}}>{record.Batteries.recovery}</span>
        </>
      )
    },
    {
      title: (
        <div>
          <div>Status</div>
          <Input
            placeholder="Status"
            value={filters.Status}
            onChange={(e) => handleFilterChange('Status', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Status',
      sorter: (a, b) => a.Status.localeCompare(b.Status),
      width: 200,     
      render(text, record) {
        return {
          props: {
            style: { 
              'background': record.Status ? allstatusColors[record.Status] : "",
              'color' : "#fff",
              'font-weight' :  "500"
            }
          },
          children: record.Status ? allstatusText[record.Status] : '-'
        };
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      width: 140,
      render: (_, record) => (
        <span>
          {record.rawData.status !== 'kyc_rejected' ? (  
            <a href='#' onClick={(event) => handleGetAllFiles(event, record)}>
              <FaFile style={{ cursor: 'pointer', marginRight: 7 }} />
            </a>
          ) : null }
          {record.rawData.registered ? (
            <>
              {/* <a href='#' onClick={(event) => handleGetAllFiles(event, record)} title="Files">
                <FaFile style={{ cursor: 'pointer', marginRight: 7 }} />
              </a> */}
              <a href='#' onClick={(event) => handleLocationDetsils(event, record)} title="Location">
                <FaMapMarker style={{ cursor: 'pointer', marginRight: 7 }} />
              </a>
              <a href='#' title="Edit" onClick={(event) => handleEdit(event, record)}>
                <FaEdit style={{ cursor: 'pointer', marginRight: 7 }} />
              </a>
              <a href='#' onClick={(event) => handleDelete(event, record)} title="Delete">
                <FaTrash style={{ cursor: 'pointer' }} />
              </a>
            </>
          ) : (   
            record.rawData.status !== 'kyc_rejected' ? (         
              <a href='#' onClick={(event) => handleVerifyUser(event, record)} title="Verify">
                <FaCheckSquare  style={{ cursor: 'pointer', marginRight: 7 }} />
              </a>
            ) : null
          )}
        </span>
      ),
    }
  ];
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    // setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  const handleChangeLocation = (value) => {
    if(value !== ''){
      getAllLeadersByLocation(value);
    } else {
      setAssociatedLeaders([]);
    }
    // setFormData(prev => ({ ...prev, teamLead: value }));
  };

  const handleChangeLeader = (value) => {
    if(value !== ''){
      getAllPartnersByLeader(value);
    } else {
      setAssociatedPartners([]);
    }
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return false;
    },
    accept: 'image/*',
    maxCount: 1
  };

  return (
    <>
      {isLoader ? (
        <PreLoader/>
      ):''}
      <SubNavbar pageName="Manage Drivers" buttons={Buttons} />
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive mt-2">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredData}
              onChange={onChange}
              scroll={{y: `calc(100vh - 280px)`}}
              pagination={{
                simple: false,
                size:"small",
                total: totalItems,
                showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                showQuickJumper: false,
                showLessItems: true,
                responsive:true,
                defaultPageSize:perPageItem,
                pageSizeOptions:[5,10,20,50,100],
                position: ['topRight'],
                onChange: (page, pageSize) => {
                  console.log(page, pageSize);
                  fetchData(page, pageSize);
                }
              }}
              bordered
            />
          </div>
        </div>
      </div>
      <Modal
        title={modalType === 'edit' ? 'Edit Driver' : 'Add Driver'}
        visible={isModalVisible}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[
          <CustomButton key="submit" onClick={handleOk} text={modalType === 'edit' ? 'Save changes' : 'Add'} />
        ]}
        width={750}
      >
        <Form form={driverForm}>
          <div className="row my-2">
            <div className="col-md-6">
              <h6>General Details</h6>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="name" className="form-label">Name:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="name" label="" rules={[{ required: true, message: 'Please enter name!' }]}>
                    <Input
                      placeholder="Name"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="mobile" className="form-label">Mobile:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="mobile" label="" rules={[{ required: true, message: 'Please enter mobile!' }]}>
                    <Input
                      placeholder="Mobile"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="alternateMobile" className="form-label">Alternate Mobile:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="alternateMobile" label="" rules={[{ required: true, message: 'Please enter alt mobile!' }]}>
                    <Input
                      placeholder="Alternate Mobile"
                      className="form-control"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <label htmlFor="localAddress" className="form-label">Local Address:</label>
                </div>
                <div className="col-8">
                  <Form.Item name="localAddress" label="" rules={[{ required: true, message: 'Please enter address!' }]}>
                    <TextArea
                      placeholder="Local Address"
                      className="form-control"
                    />
                  </Form.Item> 
                </div>
              </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="location" className="form-label">Location</label>
                  </div>
                  <div className="col-8">    
                    <Form.Item name="location" label="" rules={[{ required: true, message: 'Please select location!' }]}>          
                      <Select defaultValue="" onChange={handleChangeLocation} style={{ width: '100%' }}>
                        <Option value="">--Select Location--</Option>
                        {allLocations.map(location => (
                            <Option key={location._id} value={location._id}>{location.title}</Option>
                        ))}            
                      </Select>
                    </Form.Item>
                  </div>
              </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="leader" className="form-label">Leader</label>
                  </div>
                  <div className="col-8">    
                    <Form.Item name="leader" label="" rules={[{ required: true, message: 'Please select tl!' }]}>          
                      <Select defaultValue="" onChange={handleChangeLeader} style={{ width: '100%' }}>
                        <Option value="">--Select Leader--</Option>
                        {associatedLeaders.map(leader => (
                            <Option key={leader._id} value={leader._id}>{leader.name} | {leader.uniqueCode}</Option>
                        ))}               
                        {currentLeaderOnDriver.map(leader => (
                            <Option key={leader.id} value={leader.id}>{leader.name} | {leader.uniqueCode}</Option>
                        ))}    
                      </Select>
                    </Form.Item>
                  </div>
              </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="partner" className="form-label">Partner</label>
                  </div>
                  <div className="col-8">    
                    <Form.Item name="partner" label="" rules={[{ required: true, message: 'Please select partner!' }]}>          
                      <Select defaultValue="" onChange={handleChangeLocation} style={{ width: '100%' }}>
                        <Option value="">--Select Partner--</Option>
                        {associatedPartners.map(partner => (
                            <Option key={partner._id} value={partner._id}>{partner.companyName} | {partner.uniqueCode}</Option>
                        ))}                
                        {currentPartners.map(partner => (
                            <Option key={partner.id} value={partner.id}>{partner.name} | {partner.uniqueCode}</Option>
                        ))}          
                      </Select>
                    </Form.Item>
                  </div>
              </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="profilePictureURL" className="form-label">Profile Pic:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="profilePictureURL" label="" rules={[{ required: true, message: 'Please upload profile image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item> 
                    {editDriverDetails.profilePictureURL ?                  
                    <Image
                      width={30}
                      height={30}
                      src={editDriverDetails.profilePictureURL}
                    /> : ''}
                  </div>
                </div>
            </div>
            <div className="col-md-6">
              <div>
                <h6>&nbsp;</h6>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="aadhaarNumber_p" className="form-label">Aadhar Number:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="aadhaarNumber_p" label="" rules={[{ required: true, message: 'Please enter aadhar number!' }]}>
                      <Input
                        placeholder="Aadhar Number"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="aadhaarURL_p" className="form-label">Aadhar Image:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="aadhaarURL_p" label="" rules={[{ required: true, message: 'Please upload aadhar image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item> 
                    {editDriverDetails.aadhaarURL_p ?                  
                    <Image
                      width={30}
                      height={30}
                      src={editDriverDetails.aadhaarURL_p}
                    /> : ''}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="panNumber_p" className="form-label">PAN:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="panNumber_p" label="" rules={[{ required: true, message: 'Please enter pan number!' }]}>
                      <Input
                        placeholder="PAN"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="panURL_p" className="form-label">PAN Image:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="panURL_p" label="" rules={[{ required: true, message: 'Please upload pan image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item> 
                    {editDriverDetails.panURL_p ?                  
                    <Image
                      width={30}
                      height={30}
                      src={editDriverDetails.panURL_p}
                    /> : ''}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="vehicleRC_p" className="form-label">Vehicle RC:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="vehicleRC_p" label="" rules={[{ required: true, message: 'Please enter rc number!' }]}>
                      <Input
                        placeholder="Vehicle RC"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="vehicleRCURL_p" className="form-label">RC Image:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="vehicleRCURL_p" label="" rules={[{ required: true, message: 'Please upload rc image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item> 
                    {editDriverDetails.vehicleRCURL_p ?                  
                    <Image
                      width={30}
                      height={30}
                      src={editDriverDetails.vehicleRCURL_p}
                    /> : ''}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="vehicleChassisNumber_p" className="form-label">Chassis Number:</label>
                  </div>
                  <div className="col-8">
                    <Form.Item name="vehicleChassisNumber_p" label="" rules={[{ required: true, message: 'Please enter chassis number!' }]}>
                      <Input
                        placeholder="Chassis Number"
                        className="form-control"
                      />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="vehicleChassisURL_p" className="form-label">Chassis Number Image:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="vehicleChassisURL_p" label="" rules={[{ required: true, message: 'Please upload chassis number image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item> 
                    {editDriverDetails.vehicleChassisURL_p ?                  
                    <Image
                      width={30}
                      height={30}
                      src={editDriverDetails.vehicleChassisURL_p}
                    /> : ''}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="vehicleWithDriverURL_p" className="form-label">Driver With Vehicle Image:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="vehicleWithDriverURL_p" label="" rules={[{ required: true, message: 'Please upload  driver with vehicle image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item> 
                    {editDriverDetails.vehicleWithDriverURL_p ?                  
                    <Image
                      width={30}
                      height={30}
                      src={editDriverDetails.vehicleWithDriverURL_p}
                    /> : ''}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <label htmlFor="cibilURL_p" className="form-label">Cibil Image:</label>
                  </div>
                  <div className="col-8 images-block-upload">
                    <Form.Item name="cibilURL_p" label="" rules={[{ required: true, message: 'Please upload cibil image!' }]}>
                      <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </Form.Item> 
                    {editDriverDetails.cibilURL_p ?                  
                    <Image
                      width={30}
                      height={30}
                      src={editDriverDetails.cibilURL_p}
                    /> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
      <Modal
        title="Driver KYC"
        visible={isKYCModalVisible}
        onOk={handleKYCApprove}
        centered
        onCancel={handleCancel}
        footer={currentDriverDetails.leaderAction === 'approve' ? [
            <CustomButton key="button" onClick={handleKYCApprove} text="Approve" />,
            <CustomButton key="button" onClick={handleKYCReject} text="Reject" />
        ] : null}
        width={750}
      >
        <div className="row my-2">
          <div className="col-md-3">
            <table className='table table-bordered table-striped'>
              <thead>
                <tr>
                  <th>General Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Name:</strong> <br/>{currentDriverDetails.name}</td>
                </tr>
                <tr>
                  <td><strong>Mobile:</strong> <br/>{currentDriverDetails.mobile}</td>
                </tr>
                <tr>
                  <td><strong>Alt Mobile:</strong> <br/>{currentDriverDetails.alternateMobile}</td>
                </tr>
                <tr>
                  <td><strong>Address:</strong> <br/>{currentDriverDetails.localAddress}</td>
                </tr>
                <tr>
                  <td><strong>Image:</strong> <br/>
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.profilePictureURL}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-4">
            <table className='table table-bordered table-striped'>
              <thead>
                <tr>
                  <th colSpan={2}>Documents Uploaded By Partner</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Vehicle RC: {currentDriverDetails.vehicleRC_p}</th>
                  <td>                   
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.vehicleRCURL}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Vehicle Chesis: {currentDriverDetails.vehicleChassisNumber_p}</th>
                  <td>                                      
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.vehicleChassisURL}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Aadhar : {currentDriverDetails.aadhaarNumber_p}</th>
                  <td>                                      
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.aadhaarURL}
                    />
                  </td>
                </tr>
                <tr>
                  <th>PAN : {currentDriverDetails.panNumber_p}</th>
                  <td>                                      
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.panURL}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Cibil : </th>
                  <td>                                      
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.cibilURL}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Driver With Vehicle : </th>
                  <td>                                      
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.vehicleWithDriverURL}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-5">
            <table className='table table-bordered table-striped'>
              <thead>
                <tr>
                  <th colSpan={2}>Documents Uploaded By TL</th>
                </tr>
              </thead>
              <tbody>
                {currentDriverDetails.leaderAction === 'approve' ? (
                  <>
                  <tr>
                    <th>Vehicle RC: </th>
                    <td>                   
                      <a href='#' onClick={(event) => event.preventDefault()}><FaCheckSquare/></a>
                    </td>
                  </tr>
                  <tr>
                    <th>Vehicle Chesis: </th>
                    <td>                                      
                      <a href='#' onClick={(event) => event.preventDefault()}><FaCheckSquare/></a>
                    </td>
                  </tr>
                  <tr>
                    <th>Aadhar : </th>
                    <td>                                      
                      <a href='#' onClick={(event) => event.preventDefault()}><FaCheckSquare/></a>
                    </td>
                  </tr>
                  <tr>
                    <th>PAN : </th>
                    <td>                                      
                      <a href='#' onClick={(event) => event.preventDefault()}><FaCheckSquare/></a>
                    </td>
                  </tr> 
                  <tr>
                    <th>Selfi OutSide Home</th>
                    <td>                   
                      <Image
                        width={40}
                        height={40}
                        src={currentDriverDetails.selfieOutsideHomeURLByLeader}
                      />
                    </td>
                  </tr>  
                  <tr>
                    <th>Selfi With Driver</th>
                    <td>                   
                      <Image
                        width={40}
                        height={40}
                        src={currentDriverDetails.selfieWithDriverURLByLeader}
                      />
                    </td>
                  </tr>  
                  <tr>
                    <td colSpan={2}>     
                      <strong>PDC</strong>              
                      <br/>
                      <Space>
                        <Image
                          width={40}
                          height={40}
                          style={{ marginRight: 8 }}
                          src={currentDriverDetails.vehicleRCURLByLeader}
                        />
                        <Image
                          width={40}
                          height={40}
                          style={{ marginRight: 8 }}
                          src={currentDriverDetails.vehicleChassisURLByLeader}
                        />
                        <Image
                          width={40}
                          height={40}
                          style={{ marginRight: 8 }}
                          src={currentDriverDetails.vehicleWithDriverURLByLeader}
                        />
                        <Image
                          width={40}
                          height={40}
                          style={{ marginRight: 8 }}
                          src={currentDriverDetails.aadhaarURLByLeader}
                        />
                        <Image
                          width={40}
                          height={40}
                          src={currentDriverDetails.panURLByLeader}
                        />
                      </Space>
                    </td>
                  </tr> 
                  </>                
                ) : (
                  <>                  
                  <tr>
                    <th colSpan={2}>Action Pending From TL</th>
                  </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      <Modal
        title={mapLocationType}
        visible={isLocationModalVisible}
        centered
        onCancel={handleCancel}
        footer={null}
        width={550}
      >
        <div className="row my-2">
          <div className="col-md-12 text-center">
            <LoadScript googleMapsApiKey={gMapApiKey}>
              {mapLocationType === 'Driver Location' ? 
                <GoogleMap
                  mapContainerStyle={{ height: '450px', width: '100%' }}
                  zoom={6}
                  center={driverLocationCord}
                >
                  <Marker position={driverLocationCord}/>
                </GoogleMap>
              :   
                <GoogleMap
                  mapContainerStyle={{ height: '450px', width: '100%' }}
                  zoom={6}
                  center={mapMarkers[0] ? { lat: parseFloat(mapMarkers[0].location.latitude), lng: parseFloat(mapMarkers[0].location.longitude) } : {lat: 28.7041, lng: 77.1025}}
                >
                  {mapMarkers.map((markerData, index) => (
                    <Marker
                      key={index} // Add a unique key for each marker
                      position={{ lat: parseFloat(markerData.location.latitude), lng: parseFloat(markerData.location.longitude) }}    
                      title={markerData.batteryBoxSerialNumber}                  
                      onClick={() => handleMarkerClick(markerData)} 
                    />
                  ))}
                  {selectedMarker && (
                    <InfoWindow 
                      position={{ lat: parseFloat(selectedMarker.location.latitude), lng: parseFloat(selectedMarker.location.longitude) }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div>
                        <h6>{selectedMarker.batteryBoxSerialNumber}</h6>
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              }
            </LoadScript>
          </div>
        </div>
      </Modal>
      <Modal
        title="Driver All Files"
        visible={isFilesModalVisible}
        centered
        onCancel={handleCancel}
        footer={null}
        width={750}
      >
        <div className="row my-2">
          <div className="col-md-3">
            <table className='table table-bordered table-striped'>
              <thead>
                <tr>
                  <th>General Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Name:</strong> <br/>{currentDriverDetails.name}</td>
                </tr>
                <tr>
                  <td><strong>Mobile:</strong> <br/>{currentDriverDetails.mobile}</td>
                </tr>
                <tr>
                  <td><strong>Alt Mobile:</strong> <br/>{currentDriverDetails.alternateMobile}</td>
                </tr>
                <tr>
                  <td><strong>Address:</strong> <br/>{currentDriverDetails.localAddress}</td>
                </tr>
                <tr>
                  <td><strong>Image:</strong> <br/>
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.profilePictureURL}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-4">
            <table className='table table-bordered table-striped'>
              <thead>
                <tr>
                  <th colSpan={2}>Documents Uploaded By Partner</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Vehicle RC: {currentDriverDetails.vehicleRC_p}</th>
                  <td>                   
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.vehicleRCURL}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Vehicle Chesis: {currentDriverDetails.vehicleChassisNumber_p}</th>
                  <td>                                      
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.vehicleChassisURL}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Aadhar : {currentDriverDetails.aadhaarNumber_p}</th>
                  <td>                                      
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.aadhaarURL}
                    />
                  </td>
                </tr>
                <tr>
                  <th>PAN : {currentDriverDetails.panNumber_p}</th>
                  <td>                                      
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.panURL}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Cibil : </th>
                  <td>                                      
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.cibilURL}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Driver With Vehicle : </th>
                  <td>                                      
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.vehicleWithDriverURL}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-5">
            <table className='table table-bordered table-striped'>
              <thead>
                <tr>
                  <th colSpan={2}>Documents Uploaded By TL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Vehicle RC: </th>
                  <td>                   
                    <a href='#' onClick={(event) => event.preventDefault()}><FaCheckSquare/></a>
                  </td>
                </tr>
                <tr>
                  <th>Vehicle Chesis: </th>
                  <td>                                      
                    <a href='#' onClick={(event) => event.preventDefault()}><FaCheckSquare/></a>
                  </td>
                </tr>
                <tr>
                  <th>Aadhar : </th>
                  <td>                                      
                    <a href='#' onClick={(event) => event.preventDefault()}><FaCheckSquare/></a>
                  </td>
                </tr>
                <tr>
                  <th>PAN : </th>
                  <td>                                      
                    <a href='#' onClick={(event) => event.preventDefault()}><FaCheckSquare/></a>
                  </td>
                </tr> 
                <tr>
                  <th>Selfi OutSide Home</th>
                  <td>                   
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.selfieOutsideHomeURLByLeader}
                    />
                  </td>
                </tr>  
                <tr>
                  <th>Selfi With Driver</th>
                  <td>                   
                    <Image
                      width={40}
                      height={40}
                      src={currentDriverDetails.selfieWithDriverURLByLeader}
                    />
                  </td>
                </tr>  
                <tr>
                  <td colSpan={2}>     
                    <strong>PDC</strong>              
                    <br/>
                    <Space>
                      <Image
                        width={40}
                        height={40}
                        style={{ marginRight: 8 }}
                        src={currentDriverDetails.vehicleRCURLByLeader}
                      />
                      <Image
                        width={40}
                        height={40}
                        style={{ marginRight: 8 }}
                        src={currentDriverDetails.vehicleChassisURLByLeader}
                      />
                      <Image
                        width={40}
                        height={40}
                        style={{ marginRight: 8 }}
                        src={currentDriverDetails.vehicleWithDriverURLByLeader}
                      />
                      <Image
                        width={40}
                        height={40}
                        style={{ marginRight: 8 }}
                        src={currentDriverDetails.aadhaarURLByLeader}
                      />
                      <Image
                        width={40}
                        height={40}
                        src={currentDriverDetails.panURLByLeader}
                      />
                    </Space>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DriverDataTable;