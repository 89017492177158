import React, { useState, useRef } from 'react';
import { Form, Alert, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomButton from '../../components/Button/CustomButton';
import SideImg from '../../assests/power-hub-logo.jpg';
import './LoginOtp.css';
import { BASE_URL } from '../../components/Constant/config';


const LoginOtp = ({ onOtpSuccess, mobileNumber}) => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState(['', '', '', '']);
    const [error, setError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const inputRefs = useRef([]);

    const validateOtp = (otp) => {
        return otp.every(part => /^[0-9]{1}$/.test(part));
    };

    const handleChange = (e, index) => {
        const newOtp = [...otp];
        newOtp[index] = e.target.value;
        setOtp(newOtp);

        if (e.target.value && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateOtp(otp)) {
            setError('');
            setIsLoading(true);
            try {
                const response = await fetch(`${BASE_URL}/auth/login/verifyotp?type=login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        otp: otp.join(''),
                        mobile: mobileNumber,
                    })
                });

                const result = await response.json();
                setIsLoading(false);

                if (response.ok) {
                    localStorage.setItem('token', result.data.accessToken);
                    localStorage.setItem('loginUserData', JSON.stringify(result.data.user));
                    console.log('loginUserData--OTP Verify--', result.data);
                    navigate('/dashboard');
                    window.location.reload();
                    //setIsSubmitted(true);
                    //onOtpSuccess();
                } else {
                    setError(result.message || 'Something went wrong. Please try again.');
                }
            } catch (error) {
                setIsLoading(false);
                setError('Something went wrong. Please try again.');
            }
        } else {
            setError('Please enter a valid 4-digit OTP.');
        }
    };

    return (
        <Container className="otp-container">
            <div className="otp-content">
                <img src={SideImg} className="sideImg" alt="Side" />
                <div className="form-container">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formOtp">
                            <Form.Label>OTP Verification</Form.Label>
                            <div className="input-group otp-inputs">
                                {otp.map((value, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        value={value}
                                        onChange={(e) => handleChange(e, index)}
                                        maxLength="1"
                                        autoFocus={index === 0} 
                                        ref={el => inputRefs.current[index] = el}
                                        className="custom-input"
                                    />
                                ))}
                            </div>
                            <p className='otp-text mt-2'>
                                Didn’t you receive the OTP? <span className='resend-otp'>Resend OTP</span>
                            </p>
                            {error && <Alert variant="danger" className="mt-2 error-text">{error}</Alert>}
                            {isSubmitted && !error && <Alert variant="success" className="error-text mt-3">OTP verification successful</Alert>}
                        </Form.Group>
                        <div className="button-container">
                            <CustomButton text="Verify OTP" isLoading={isLoading} />
                        </div>
                    </Form>
                </div>
            </div>
        </Container>
    );
};

export default LoginOtp;

