import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar.jsx';
import Location from './pages/Location.js';
import Inventory from './pages/Inventory';
import Partners from './pages/Partners.js';
import Driver from './pages/Driver.js';
import Login from './pages/LoginPage/Login.js';
import Manufactured from './pages/Manufactured.js';
import RecoverBatery from './pages/RecoverBatery.js';
import HelpTicket from './pages/HelpTicket.js';
import TeamLead from './pages/TeamLead.js';
import UserRoles from './pages/UserRoles.js';
import GlobalSetting from './components/Table/GlobalSetting.js';
import PromotionalContent from './components/Table/PromotionalContent.js';
import IssuesSettings from './components/Table/IssuesSettings.js';
import OffersSettings from './components/Table/OffersSettings.js';
import Immobilize from './pages/Immobilize.js';
import ManageCollection from './pages/ManageCollection.js';
import ManagePayment from './pages/ManagePayments.js';
import Dashboard from './pages/Dashboard.js';
import HealthReport from './pages/HealthReport.js';
import MyProfile from './pages/MyProfile.js';
import LogOut from './pages/LoginPage/LogOut.js';
import Error404 from './pages/ErrorPages/404.js';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkIsUserLoggedIn = () => {
    const userToken = localStorage.getItem('token');
    if(userToken !== null){
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }

  useEffect(() => {
    checkIsUserLoggedIn();
  });

  return (
    <BrowserRouter>
        <Sidebar>
          <div className="content">
            <Routes>
              <Route exact path="/" element={isLoggedIn ? <Navigate replace to={"/dashboard"}/> : <Navigate replace to={"/login"}/>}/>
              <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/health-report" element={isLoggedIn ? <HealthReport /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/my-profile" element={isLoggedIn ? <MyProfile /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/locations" element={isLoggedIn ? <Location /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/inventories" element={isLoggedIn ? <Inventory /> : <Navigate replace to={"/login"}/>} />
              <Route path="/partners" element={isLoggedIn ? <Partners /> : <Navigate replace to={"/login"}/>} />
              <Route path="/drivers" element={isLoggedIn ? <Driver /> : <Navigate replace to={"/login"}/>} />
              <Route path="/manufacturers" element={isLoggedIn ? <Manufactured /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/recover-batteries" element={isLoggedIn ? <RecoverBatery /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/help-tickets" element={isLoggedIn ? <HelpTicket /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/team-leads" element={isLoggedIn ? <TeamLead /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/settings/user-roles" element={isLoggedIn ? <UserRoles /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/settings/global-settings" element={isLoggedIn ? <GlobalSetting/> : <Navigate replace to={"/login"}/>}/>
              <Route path="/immobilize" element={isLoggedIn ? <Immobilize/> : <Navigate replace to={"/login"}/>}/>
              <Route path="/manage-collections" element={isLoggedIn ? <ManageCollection/> : <Navigate replace to={"/login"}/>} />
              <Route path="/manage-payments" element={isLoggedIn ? <ManagePayment/> : <Navigate replace to={"/login"}/>}/>
              <Route path="/settings/global" element={isLoggedIn ? <GlobalSetting /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/settings/issues" element={isLoggedIn ? <IssuesSettings /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/settings/offers" element={isLoggedIn ? <OffersSettings /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/settings/promotional-content" element={isLoggedIn ? <PromotionalContent /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/user/logout" element={isLoggedIn ? <LogOut /> : <Navigate replace to={"/login"}/>}/>
              <Route path="/login" element={!isLoggedIn ? <Login /> : <Navigate replace to={"/dashboard"}/>}/>
              <Route path="/*" element={<Error404 />} />
            </Routes>
          </div>
        </Sidebar>
      </BrowserRouter>
  );
};

export default App;
