import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Select, message, Table, Modal, Space, Upload, Button, DatePicker, Image } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import CustomButton from '../Button/CustomButton';
import { FaEdit } from 'react-icons/fa';
import colors from '../Constant/Color';
import SubNavbar from '../Header/SubNavbar';
import PreLoader from '../PreLoader/PreLoader';
import { BASE_URL } from '../Constant/config';
import { UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

let allstatusText = [];
allstatusText['pending'] = 'Pending';
allstatusText['paid'] = 'Paid';

let allstatusColors = [];
allstatusColors['paid'] = '#248B22';
allstatusColors['pending'] = '#FF4500';

const ManagePaymentTable = () => {
    const [paymentForm] = Form.useForm();
    const [isLoader, setIsLoader] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedTab, setSelectedTab] = useState('Partner');
    const [overAllCollectionData, setOverAllCollectionData] = useState({});
    const [perPageItemDriver, setPerPageItemDriver] = useState(5);
    const [currentPageDriver, setCurrentPageDriver] = useState(1);
    const [totalItemsDriver, setTotalItemsDriver] = useState(0);
    const [dataSourceDriver, setDataSourceDriver] = useState([]);
    const [perPageItemPartner, setPerPageItemPartner] = useState(5);
    const [currentPagePartner, setCurrentPagePartner] = useState(1);
    const [totalItemsPartner, setTotalItemsPartner] = useState(0);
    const [dataSourcePartner, setDataSourcePartner] = useState([]);
    const [perPageItemTeamLead, setPerPageItemTeamLead] = useState(5);
    const [currentPageTeamLead, setCurrentPageTeamLead] = useState(1);
    const [totalItemsTeamLead, setTotalItemsTeamLead] = useState(0);
    const [dataSourceTeamLead, setDataSourceTeamLead] = useState([]);
    const [editPaymentDetails, setEditPaymentDetails] = useState({});

    const onChange = (pagination, filters, sorter, extra) => {
      if (selectedTab === 'Driver') {
        setCurrentPageDriver(pagination.current);
        setPerPageItemDriver(pagination.pageSize);
      } else if (selectedTab === 'Partner') { 
        setCurrentPagePartner(pagination.current);
        setPerPageItemPartner(pagination.pageSize);
      } else if (selectedTab === 'TeamLead') { 
        setCurrentPageTeamLead(pagination.current);
        setPerPageItemTeamLead(pagination.pageSize);
      }
    };

    const handelOnClickTab = async (currentTabname) => {
        setSelectedTab(currentTabname);
        if (currentTabname === 'Driver') {
            fetchDriverCollection(currentPageDriver, perPageItemDriver);
        } else if (currentTabname === 'Partner') {
            fetchPartnerCollection(currentPagePartner, perPageItemPartner);
        } else if (currentTabname === 'TeamLead') {
            fetchTeamLeadCollection(currentPageTeamLead, perPageItemTeamLead);
        }
    } 

    const fetchDataOnPagination = async (page, pageSize) =>{
        setIsLoader(true);
        if (selectedTab === 'Driver') {
            fetchDriverCollection(page, pageSize);
        } else if (selectedTab === 'Partner') {
            fetchPartnerCollection(page, pageSize);
        } else if (selectedTab === 'TeamLead') {
            fetchTeamLeadCollection(page, pageSize);
        } 
    }

    const fetchOverAllCollection = async (page, pageSize) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${BASE_URL}/payments/get-payments-summary`,{
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            });
            const result = await response.json();
        if (result.statusCode === 200) {
            setOverAllCollectionData(result.data);
        } else {
            console.error('Failed to fetch data:', result.message);
        }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchDriverCollection = async (page, pageSize) => {
      try {
        setIsLoader(true);
        const token = localStorage.getItem('token');
        const response = await fetch(`${BASE_URL}/payments/get-payments?date=quarter&role=driver&limit=${pageSize}&page=${page}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        if (result.statusCode === 200) {
          setDataSourceDriver(result.data);
          setTotalItemsDriver(result.pagination.totalItems);
          setIsLoader(false);
        } else {
          console.error('Failed to fetch data:', result.message);
          setIsLoader(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoader(false);
      }
    };

    const fetchPartnerCollection = async (page, pageSize) => {
      try {
        setIsLoader(true);
        const token = localStorage.getItem('token');
        const response = await fetch(`${BASE_URL}/payments/get-payments?date=quarter&role=partner&limit=${pageSize}&page=${page}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        if (result.statusCode === 200) {
          setDataSourcePartner(result.data);
          setTotalItemsPartner(result.pagination.totalItems);
          setIsLoader(false);
        } else {
          console.error('Failed to fetch data:', result.message);
          setIsLoader(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoader(false);
      }
    };

    const fetchTeamLeadCollection = async (page, pageSize) => {
      try {
        setIsLoader(true);
        const token = localStorage.getItem('token');
        const response = await fetch(`${BASE_URL}/payments/get-payments?date=quarter&role=leader&limit=${pageSize}&page=${page}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        if (result.statusCode === 200) {
          setDataSourceTeamLead(result.data);
          setTotalItemsTeamLead(result.pagination.totalItems);
          setIsLoader(false);
        } else {
          console.error('Failed to fetch data:', result.message);
          setIsLoader(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoader(false);
      }
    };

    const handleUpdatePayment = async (event, record) => {
        event.preventDefault();  
        setIsLoader(true); 
        try {
            const token = localStorage.getItem('token');
            const paymentId = record._id;
            const response = await fetch(`${BASE_URL}/payments/get-payment/${paymentId}`, {
                headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result.statusCode === 200) {
                paymentForm.resetFields();
                setEditPaymentDetails(result.data);
                setIsModalVisible(true);
                setIsLoader(false);  
            } else {
                console.error('Failed to fetch data:', result.message);
                setIsLoader(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoader(false);
        }
    }

    const handleOk = async () => {
        const validationResult = paymentForm.validateFields().then(async (values) => {
            setIsLoader(true);
            try {
              const token = localStorage.getItem('token');
              const FormData = require('form-data');
              const formData = new FormData();           
              formData.append('paymentDate', dayjs(values.paymentDateInApp).format('DD-MM-YYYY')); 
              formData.append('transactionID', values.transactionID); 
              formData.append('remarks', values.remarks); 
              values.screenshot.fileList ? formData.append('screenshot', values.screenshot.fileList[0].originFileObj) : formData.delete("screenshot");
              const paymentId = editPaymentDetails._id;
              const response = await fetch(`${BASE_URL}/payments/update-payment/${paymentId}`, {
                method: 'PUT',
                headers: {
                  'Authorization': `Bearer ${token}`
                },
                body: formData,
              });
              const result = await response.json();
              if (result.statusCode === 200 || result.statusCode === 201) {
                message.success(result.message);
                setIsModalVisible(false);
                setIsLoader(false);              
                if (selectedTab === 'Driver') {
                    fetchDriverCollection(currentPageDriver, perPageItemDriver);
                } else if (selectedTab === 'Partner') {
                    fetchPartnerCollection(currentPagePartner, perPageItemPartner);
                } else if (selectedTab === 'TeamLead') {
                    fetchTeamLeadCollection(currentPageTeamLead, perPageItemTeamLead);
                }
              } else {
                message.error('Failed to create manufacturer - ' + result.message);
                setIsLoader(false);
              }
            } catch (error) {
              message.error('Error creating manufacturer', error);
              setIsLoader(false);
            }
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }

    const handleCancel = async () => {
        setIsModalVisible(false);
    }

    const columnsDriver = [
        {
            title: 'Driver Code',
            dataIndex: 'uniqueCode',
            sorter: (a, b) => a.uniqueCode.localeCompare(b.uniqueCode),
            width: 200,
            render: (_, record) => (
                <span>{record.uniqueCode}</span>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: 200,
            render: (_, record) => (
                <span>{record.name}</span>
            ),
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            sorter: (a, b) => a.mobile.localeCompare(b.mobile),
            width: 200,
            render: (_, record) => (
                <span>{record.mobile}</span>
            ),
        },
        {
            title: 'Associate Person',
            dataIndex: 'associatedPersonName',
            sorter: (a, b) => a.associatedPersonName.localeCompare(b.associatedPersonName),
            width: 200,
            render: (_, record) => (
                <span>{record.associatedPersonName ? record.associatedPersonName : '-'}</span>
            ),
        },
        {
            title: 'Location',
            dataIndex: 'location',
            sorter: (a, b) => a.location.localeCompare(b.location),
            width: 200,
            render: (_, record) => (
                <strong>{record?.location?.locality}<br></br>{record?.location?.city}<br></br>{record?.location?.state}<br></br>{record?.location?.pincode}</strong>
            ),
        },
        {
          title: 'Payment Type',
          dataIndex: 'paymentType',
          sorter: (a, b) => a.paymentType.localeCompare(b.paymentType),
          width: 200
        },
        {
          title: 'Amount Payable',
          dataIndex: 'amountPayable',
          sorter: (a, b) => a.amountPayable.localeCompare(b.amountPayable),
          width: 200,
          render: (_, record) => (
              <strong>{record?.amountPayable}</strong>
          ),
        },
        {
          title: 'Date Of Payment',
          dataIndex: 'paymentDate',
          sorter: (a, b) => a.paymentDate.localeCompare(b.paymentDate),
          width: 200,
          render: (_, record) => (
            <>
              {record.paymentDate ? (
                dayjs(record.paymentDate).format('DD/MM/YYYY')
              ) : (
                '-'
              )}
            </>
          )
        }
    ];

    const columnsPartner = [
        {
            title: 'Partner Code',
            dataIndex: 'uniqueCode',
            sorter: (a, b) => a.uniqueCode.localeCompare(b.uniqueCode),
            width: 200,
            render: (_, record) => (
                <span>{record.uniqueCode}</span>
            ),
        },
        {
            title: 'Name & Mobile',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: 200,
            render: (_, record) => (
                <span>{record.name}<br></br>{record.mobile}</span>
            ),
        },
        {
          title: 'Location',
          dataIndex: 'location',
          sorter: (a, b) => a.location.localeCompare(b.location),
          width: 200,
          render: (_, record) => (
              <strong>{record?.location?.locality}<br></br>{record?.location?.city}<br></br>{record?.location?.state}<br></br>{record?.location?.pincode}</strong>
          ),
        },
        {
          title: 'Payment Type',
          dataIndex: 'paymentType',
          sorter: (a, b) => a.paymentType.localeCompare(b.paymentType),
          width: 200
        },
        {
          title: 'Associate Person',
          dataIndex: 'associatedPersonName',
          sorter: (a, b) => a.associatedPersonName.localeCompare(b.associatedPersonName),
          width: 200
        },
        {
          title: 'Total Amount',
          dataIndex: 'totalAmount',
          sorter: (a, b) => a.totalAmount.localeCompare(b.totalAmount),
          width: 200,
          render: (_, record) => (
              <strong>{record?.totalAmount}</strong>
          ),
        },
        {
          title: 'TDS Deduction',
          dataIndex: 'deduction',
          sorter: (a, b) => a.deduction.localeCompare(b.deduction),
          width: 200,
          render: (_, record) => (
              <strong>{record?.deduction}</strong>
          ),
        },
        {
          title: 'Amount Payable',
          dataIndex: 'amountPayable',
          sorter: (a, b) => a.amountPayable.localeCompare(b.amountPayable),
          width: 200,
          render: (_, record) => (
              <strong>{record?.amountPayable}</strong>
          ),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          sorter: (a, b) => a.status.localeCompare(b.status),
          width: 200,   
          render(text, record) {
            return {
              props: {
                style: { 
                  'background': record.status ? allstatusColors[record.status] : "",
                  'color' : "#fff",
                  'font-weight' :  "500"
                }
              },
              children: record.status ? allstatusText[record.status] : '-'
            };
          }
        },
        {
          title: 'Date Of Payment',
          dataIndex: 'paymentDate',
          sorter: (a, b) => a.paymentDate.localeCompare(b.paymentDate),
          width: 200,
          render: (_, record) => (
            <>
              {record.paymentDate ? (
                dayjs(record.paymentDate).format('DD/MM/YYYY')
              ) : (
                '-'
              )}
            </>
          )
        },
        {
          title: 'Action',
          dataIndex: 'action',
          fixed: 'right',
          width: 80,
          render: (_, record) => (
            <>
              {record.status === 'pending' ? (
                <a href='#' onClick={(event) => handleUpdatePayment(event, record)}>
                    <FaEdit style={{ cursor: 'pointer' }} />
                </a>
              ) : (
                ''
              )}
            </>
          )
        }
    ];

    const columnsTeamLead = [
        {
            title: 'TL Code',
            dataIndex: 'uniqueCode',
            sorter: (a, b) => a.uniqueCode.localeCompare(b.uniqueCode),
            width: 200,
            render: (_, record) => (
                <span>{record.uniqueCode}</span>
            ),
        },
        {
            title: 'Name & Mobile',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: 200,
            render: (_, record) => (
                <span>{record.name}<br></br>{record.mobile}</span>
            ),
        },
        {
          title: 'Location',
          dataIndex: 'location',
          sorter: (a, b) => a.location.localeCompare(b.location),
          width: 200,
          render: (_, record) => (
              <strong>{record?.location?.locality}<br></br>{record?.location?.city}<br></br>{record?.location?.state}<br></br>{record?.location?.pincode}</strong>
          ),
        },
        {
          title: 'Payment Type',
          dataIndex: 'paymentType',
          sorter: (a, b) => a.paymentType.localeCompare(b.paymentType),
          width: 200
        },
        {
          title: 'Associate Person',
          dataIndex: 'associatedPersonName',
          sorter: (a, b) => a.associatedPersonName.localeCompare(b.associatedPersonName),
          width: 200
        },
        {
          title: 'Total Amount',
          dataIndex: 'totalAmount',
          sorter: (a, b) => a.totalAmount.localeCompare(b.totalAmount),
          width: 200,
          render: (_, record) => (
              <strong>{record?.totalAmount}</strong>
          ),
        },
        {
          title: 'TDS Deduction',
          dataIndex: 'deduction',
          sorter: (a, b) => a.deduction.localeCompare(b.deduction),
          width: 200,
          render: (_, record) => (
              <strong>{record?.deduction}</strong>
          ),
        },
        {
          title: 'Amount Payable',
          dataIndex: 'amountPayable',
          sorter: (a, b) => a.amountPayable.localeCompare(b.amountPayable),
          width: 200,
          render: (_, record) => (
              <strong>{record?.amountPayable}</strong>
          ),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          sorter: (a, b) => a.status.localeCompare(b.status),
          width: 200,   
          render(text, record) {
            return {
              props: {
                style: { 
                  'background': record.status ? allstatusColors[record.status] : "",
                  'color' : "#fff",
                  'font-weight' :  "500"
                }
              },
              children: record.status ? allstatusText[record.status] : '-'
            };
          }
        },
        {
          title: 'Date Of Payment',
          dataIndex: 'paymentDate',
          sorter: (a, b) => a.paymentDate.localeCompare(b.paymentDate),
          width: 200,
          render: (_, record) => (
            <>
              {record.paymentDate ? (
                dayjs(record.paymentDate).format('DD/MM/YYYY')
              ) : (
                '-'
              )}
            </>
          )
        },
        {
          title: 'Action',
          dataIndex: 'action',
          fixed: 'right',
          width: 80,
          render: (_, record) => (
            <>
              {record.status === 'pending' ? (
                <a href='#' onClick={(event) => handleUpdatePayment(event, record)}>
                    <FaEdit style={{ cursor: 'pointer' }} />
                </a>
              ) : (
                ''
              )}
            </>
          )
        }
    ];

    const buttonStyle = {
        outline: 'none',
        border: 'none',
        margin: '0.75rem',
        width:"150px",
        height:"50px",
        color:colors.primary
    };

    const activeStyle = {
        backgroundColor: '#CDCDCD', 
        color: colors.primary, 
        width:"150px",
        height:"50px"
    };

    const uploadProps = {
      beforeUpload: (file) => {
        return false;
      },
      accept: 'image/*',
      maxCount: 1
    };

    useEffect(() => {
      handelOnClickTab(selectedTab);
      fetchOverAllCollection();
    }, []);

    return (
        <>
            {isLoader ? (
            <PreLoader/>
            ):''}
            <SubNavbar
            pageName="Manage Payments"
            buttons={[]}
            />
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className='col-12 col-sm-4'>
                            <div class="card text-bg-primary">
                                <div class="card-body">
                                    <h5 class="card-title">Today's Payment</h5>
                                    <h6 class="card-text">{overAllCollectionData.todayTotal} INR</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-4'>
                            <div class="card text-bg-primary">
                                <div class="card-body">
                                    <h5 class="card-title">Current week's Payment</h5>
                                    <h6 class="card-text">{overAllCollectionData.weekTotal} INR</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-4'>
                            <div class="card text-bg-primary">
                                <div class="card-body">
                                    <h5 class="card-title">Current Month's Payment</h5>
                                    <h6 class="card-text">{overAllCollectionData.monthTotal} INR</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <div className='mt-4' style={{ backgroundColor: "#F1F3F4" }}>
              <div>
                <div className="row">
                    <div className="d-flex">
                        <button
                            style={{
                                ...buttonStyle,
                                ...(selectedTab === 'Partner' ? activeStyle : {}),
                            }}
                            onClick={() => handelOnClickTab('Partner')}>
                            Partner
                        </button>
                        <button
                            style={{
                                ...buttonStyle,
                                ...(selectedTab === 'Driver' ? activeStyle : {}),
                            }}
                            onClick={() => handelOnClickTab('Driver')}>
                            Driver
                        </button>
                        <button
                            style={{
                                ...buttonStyle,
                                ...(selectedTab === 'TeamLead' ? activeStyle : {}),
                            }}
                            onClick={() => handelOnClickTab('TeamLead')}>
                            Team Lead
                        </button>
                    </div>
                    <div className="col-sm-12">
                        <div className="table-responsive mt-2">  
                            <>  
                                {selectedTab == 'Driver'?  (
                                    <Table
                                    rowKey={(record) => record._id}
                                    columns={columnsDriver}
                                    dataSource={dataSourceDriver}
                                    onChange={onChange}
                                    scroll={{y: `calc(100vh - 380px)`}}
                                    pagination={{
                                      simple: false,
                                      size:"small",
                                      total: totalItemsDriver,
                                      showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                      showSizeChanger: true,
                                      showQuickJumper: false,
                                      showLessItems: true,
                                      responsive:true,
                                      defaultPageSize:perPageItemDriver,
                                      pageSizeOptions:[5,10,20,50,100],
                                      position: ['topRight'],
                                      onChange: (page, pageSize) => {
                                        fetchDataOnPagination(page, pageSize);
                                      }
                                    }}
                                    bordered
                                  />
                                ) : ''} 
                                {selectedTab == 'Partner'?  (
                                    <Table
                                    rowKey={(record) => record._id}
                                    columns={columnsPartner}
                                    dataSource={dataSourcePartner}
                                    onChange={onChange}
                                    scroll={{y: `calc(100vh - 380px)`}}
                                    pagination={{
                                      simple: false,
                                      size:"small",
                                      total: totalItemsPartner,
                                      showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                      showSizeChanger: true,
                                      showQuickJumper: false,
                                      showLessItems: true,
                                      responsive:true,
                                      defaultPageSize:perPageItemPartner,
                                      pageSizeOptions:[5,10,20,50,100],
                                      position: ['topRight'],
                                      onChange: (page, pageSize) => {
                                        fetchDataOnPagination(page, pageSize);
                                      }
                                    }}
                                    bordered
                                  />
                                ) : ''} 
                                {selectedTab == 'TeamLead'?  (
                                    <Table
                                    rowKey={(record) => record._id}
                                    columns={columnsTeamLead}
                                    dataSource={dataSourceTeamLead}
                                    onChange={onChange}
                                    scroll={{y: `calc(100vh - 380px)`}}
                                    pagination={{
                                      simple: false,
                                      size:"small",
                                      total: totalItemsTeamLead,
                                      showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                      showSizeChanger: true,
                                      showQuickJumper: false,
                                      showLessItems: true,
                                      responsive:true,
                                      defaultPageSize:perPageItemTeamLead,
                                      pageSizeOptions:[5,10,20,50,100],
                                      position: ['topRight'],
                                      onChange: (page, pageSize) => {
                                        fetchDataOnPagination(page, pageSize);
                                      }
                                    }}
                                    bordered
                                  />
                                ) : ''} 
                            </>
                        </div>
                    </div>
                </div>
              </div>
            </div>            
            <Modal
                title="Update Payment"
                visible={isModalVisible} 
                onOk={handleOk} 
                centered
                onCancel={handleCancel}
                footer={[
                    <CustomButton key="submit" onClick={handleOk} text={"Update"} />
                ]}
                width={750}
                >                
                <div className="row mt-4 mb-2">
                    <div className="col-md-6">                        
                        <table className='table table-bordered table-striped'>
                            <tbody>
                                <tr>
                                    <th>Name:</th>
                                    <td>{editPaymentDetails?.name}</td>
                                </tr>
                                <tr>
                                    <th>Mobile:</th>
                                    <td>{editPaymentDetails?.mobile}</td>
                                </tr>
                                <tr>
                                    <th>Total Amount:</th>
                                    <td>{editPaymentDetails?.totalAmount}</td>
                                </tr>
                                <tr>
                                    <th>TDS Deduction:</th>
                                    <td>{editPaymentDetails?.deduction}</td>
                                </tr>
                                <tr>
                                    <th>Amount Payable:</th>
                                    <td>{editPaymentDetails?.amountPayable}</td>
                                </tr>
                                <tr>
                                    <th>Location:</th>
                                    <td>                                        
                                        {editPaymentDetails?.location?.locality}<br></br>
                                        {editPaymentDetails?.location?.city}<br></br> 
                                        {editPaymentDetails?.location?.state}<br></br>
                                        {editPaymentDetails?.location?.pincode}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6">
                        <Form form={paymentForm}>
                            <div className="row mb-2">
                                <div className="col-4">
                                    <label htmlFor="transactionID" className="form-label">Transection ID:</label>
                                </div>
                                <div className="col-8">
                                    <Form.Item name="transactionID" label="" rules={[{ required: true, message: 'Please enter transaction ID!' }]}>
                                        <Input
                                            placeholder="transactionID"
                                            className="form-control"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-4">
                                    <label htmlFor="paymentDateInApp" className="form-label">Payment Date:</label>
                                </div>
                                <div className="col-8">
                                    <Form.Item name="paymentDateInApp" label="" rules={[{ required: true, message: 'Please enter payment date!' }]}>
                                        <DatePicker
                                            className="form-control"
                                            format="DD/MM/YYYY"
                                            maxDate={dayjs(new Date())}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-4">
                                    <label htmlFor="screenshot" className="form-label">Upload Screenshot:</label>
                                </div>
                                <div className="col-8 images-block-upload">
                                    <Form.Item name="screenshot" label=""rules={[{ required: true, message: 'Please upload screenshot!' }]}>
                                        <Upload {...uploadProps}>
                                            <Button icon={<UploadOutlined />}>Select File</Button>
                                        </Upload>
                                    </Form.Item>  
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-4">
                                    <label htmlFor="remarks" className="form-label">Remarks</label>
                                </div>
                                <div className="col-8">
                                    <Form.Item name="remarks" label="" rules={[{ required: true, message: 'Please enter remarks!' }]}> 
                                        <TextArea placeholder='Remarks' />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ManagePaymentTable;
