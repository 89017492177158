import React from 'react';
import PartnersDataTable from '../components/Table/PartnersDataTable';

function Partners() {
return (
    <div>
          <PartnersDataTable/>
    </div>
  );
}

export default Partners;
