import React, { useState, useEffect } from 'react';

const Error404 = () => {
    return (
        <div class="d-flex justify-content-center align-items-center text-center w-100 vh-100">
            <div>
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <p>
                    Sorry, the page you are looking
                    for does not exist.
                </p>
                <p>
                    <a class="btn bsb-btn-5xl btn-dark rounded-pill px-5 fs-6 m-0" href="/login" role="button">
                        Back to Home
                    </a>
                </p>
            </div>
        </div>
    );
};
export default Error404;

