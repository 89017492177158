import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { height } from '@fortawesome/free-solid-svg-icons/fa0';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;
 
class LineChart extends Component {
	constructor() {
		super();
	}
	render() {
		const { reportData } = this.props;
		const options = {
            animationEnabled: true,
            height: 210,
            toolTip: {
                shared: "true"
            },
            legend:{
                cursor:"pointer",
            },
            // axisY:{    
            //   valueFormatString: "#K,.",
            //   interval: 50000
            // },
            data: reportData
		}
		
		return (
		<div>
			<CanvasJSChart options = {options} 
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}
}

export default LineChart;