import React, { useState, useEffect } from 'react';
import { Table, Modal, Input, Alert, message, Select, Tag, Form } from 'antd';
import SubNavbar from '../Header/SubNavbar';
import { FaEdit, FaTrash } from 'react-icons/fa';
import CustomButton from '../Button/CustomButton';
import { BASE_URL } from '../Constant/config';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';


const UserRolesTable = () => {
  const [dataSource, setDataSource] = useState([]);
  const [roleForm] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [filters, setFilters] = useState({
    Permissions: '',
    User: '',
  });
  const [formData, setFormData] = useState({
    name: '',
    permissions: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [perPageItem, setPerPageItem] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [currentRoleDetails, setCurrentRoleDetails] = useState({});
  const allowedForEdit = ['admin'];
  const allowedForDelete = ['admin', 'partner', 'leader', 'driver', 'manufacturer'];
  const { Option } = Select;

  useEffect(() => {
    fetchData(currentPage, perPageItem);
    fetchPermissions();
  }, []);
  

  const fetchPermissions = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${BASE_URL}/custom/get-permissions`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (response.ok) {
        //console.log('Fetched permissions:', result.data); 
        setPermissions(result.data);
        //console.log(permissions);
      } else {
        setError(result.message || 'Failed to fetch permissions.');
      }
    } catch (error) {
      setError('Something went wrong. Please try again.');
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${BASE_URL}/custom/get-roles`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (response.ok) {
        const formattedData = result.data.map(role => ({
          key: role._id,
          _id: role._id,
          User: role.name,
          rawData: role,
          Permissions: role.permissions.map(p => p.name).join(', '),
          permissionsAll: role.permissions.map(p => p._id),
        }));
        setDataSource(formattedData);
      } else {
        setError(result.message || 'Failed to fetch roles.');
      }
    } catch (error) {
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (record) => {
    roleForm.resetFields();
    roleForm.setFieldsValue({ 
      name: record.User,
      permissions: record.permissionsAll
    });
    setCurrentRoleDetails(record);
    setIsEditing(true);
    setIsModalVisible(true);
  };

  const handleDelete = (key) => {
    console.log('Delete clicked for key:', key);
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  const handleChangeSelectTL = (value) => {
    console.log(value);
    setFormData(prev => ({ ...prev, permissions: value }));
  };

  const handleAddClick = () => {
    roleForm.resetFields();
    setIsEditing(false);
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    if(isEditing){
      const validationResult = roleForm.validateFields(['name', 'permissions']).then(async (values) => {        
        try {
          const token = localStorage.getItem('token');
          const formDataSubmit = roleForm.getFieldsValue(true);
          let currentRoleId = currentRoleDetails._id;
          const response = await fetch(`${BASE_URL}/custom/update-role/${currentRoleId}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formDataSubmit),
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success('Role updated successfully');
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
            roleForm.resetFields();
          } else {
            message.error('Failed to update role - ' + result.message);
          }
        } catch (error) {
          message.error('Error updateing role!');
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    } else {
      const validationResult = roleForm.validateFields(['name', 'permissions']).then(async (values) => {  
        try {
          const token = localStorage.getItem('token');
          const formDataSubmit = roleForm.getFieldsValue(true);
          const response = await fetch(`${BASE_URL}/custom/create-role`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formDataSubmit),
          });
          const result = await response.json();
          if (result.statusCode === 200 || result.statusCode === 201) {
            message.success('Role created successfully');
            fetchData(currentPage, perPageItem);
            setIsModalVisible(false);
            roleForm.resetFields();
          } else {
            message.error('Failed to create role - ' + result.message);
          }
        } catch (error) {
          message.error('Error creating role!');
        }
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
    }
  };

  const handleModalCancel = () => {
    console.log('Modal Cancel clicked');
    setIsModalVisible(false);
  };

  const Buttons = [
    { text: 'Add Role', onClick: handleAddClick },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFilterChange = (dataIndex, value) => {
    setFilters((prev) => ({
      ...prev,
      [dataIndex]: value,
    }));
  };

  const filteredDataSource = dataSource.filter((item) =>
    Object.keys(filters).every((key) =>
      item[key]?.toString().toLowerCase().includes(filters[key].toLowerCase())
    )
  );

  const columns = [
    {
      title: (
        <div>
          <div>User Roles</div>
          <Input
            placeholder="Search User Roles"
            value={filters.User}
            onChange={(e) => handleFilterChange('User', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'User',      
      width:'170px',
      sorter: (a, b) => a.User.localeCompare(b.User),
    },
    {
      title: (
        <div>
          <div>User Permissions</div>
          <Input
            placeholder="Search User Permissions"
            value={filters.Permissions}
            onChange={(e) => handleFilterChange('Permissions', e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      dataIndex: 'Permissions',
      sorter: (a, b) => a.Permissions.localeCompare(b.Permissions),      
      render: (text, record) => (
        record.rawData.permissions.map(permission => (
          <>            
            <Tag color='green' className='mb-2'>
              {permission.name}
            </Tag>
          </>
        ))
      ),
    },
    {
      title: (<div style={{ textAlign: 'center' }}> Action </div>),
      dataIndex: 'action',
      width:'100px',
      render: (_, record) => (
        <div style={{ textAlign: 'center' }}> 
          <>
            {!allowedForEdit.includes(record.rawData.name) ? (              
              <a href="#">
                <FaEdit onClick={() => handleEdit(record)} style={{ cursor: 'pointer', marginRight: 16 }} />
              </a>  
            ) : (
              <a style={{width:'14px',display:'inline-block',marginRight:16}}>&nbsp;</a>
            )}
            {!allowedForDelete.includes(record.rawData.name) ? (       
              <a href="#">
                <FaTrash onClick={() => handleDelete(record.key)} style={{ cursor: 'pointer' }} />
              </a>
            ) : (
              <a style={{width:'14px',display:'inline-block',marginRight:16}}>&nbsp;</a>
            )}
          </>
        </div>
      ),
      align: 'center',
    },
  ];

  return (
    <>
      <SubNavbar
        pageName="User Roles"
        buttons={Buttons}
      />
      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive mt-2">
            {loading ? (
              <div>Loading...</div>
            ) : error ? (
              <Alert message={error} type="error" />
            ) : (
              <Table
                columns={columns}
                dataSource={dataSource}
                rowKey={(record) => record.key}
                onChange={onChange}
                scroll={{y: `calc(100vh - 380px)`}}
                bordered
                pagination={{
                  simple: false,
                  size:"small",
                  total: totalItems,
                  showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  showSizeChanger: true,
                  showQuickJumper: false,
                  showLessItems: true,
                  responsive:true,
                  defaultPageSize:perPageItem,
                  pageSizeOptions:[5,10,20,50,100],
                  position: ['topRight'],
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        title={isEditing ? "Edit User Role" : "Add User Role"}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <CustomButton key="submit" onClick={handleModalOk} text={isEditing ? "Save Changes" : "Add User Role"} />
        ]}
      >
        <Form form={roleForm}>
          <div className="row m-2">
              <div className='col-12'>
                <div className="row mb-3">
                  <div className="col-3">
                    <label htmlFor="user" className="form-label">User:</label>
                  </div>
                  <div className="col-9">
                    <Form.Item name="name" label="" rules={[{ required: true, message: 'Please enter name.!' }]}>
                        <Input
                          placeholder="Role Name"
                          className="form-control"
                        />
                    </Form.Item> 
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-3">
                    <label htmlFor="permissions" className="form-label">Permissions:</label>
                  </div>
                  <div className="col-9">   
                    <Form.Item name="permissions" label="" rules={[{ required: true, message: 'Please select permissions.!' }]}>                    
                      <Select onChange={handleChangeSelectTL} style={{ width: '100%' }} defaultValue={[]} mode="multiple" allowClear>
                        {permissions.map(permission => (
                            <Option key={permission._id} value={permission._id}>{permission.name}</Option>
                        ))}            
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
        </Form>
      </Modal>
    </>
  );
};

export default UserRolesTable;
