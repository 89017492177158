import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;
 
class ColumnChart extends Component {
	constructor() {
		super();
	}
	render() {
		const { reportData } = this.props;
		const options = {			
            animationEnabled: true,	
            height: 220,
            toolTip: {
                shared: true
            },
            legend: {
                cursor:"pointer"
            },
            axisX:{   
                interval: 1
            },
            axisY:{    
              valueFormatString: "#K,.",
              interval: 50000
            },
            axisY2:{    
              valueFormatString: "#K,.",
              interval: 50000
            },
            dataPointWidth: 10,
            data: reportData
		}
		
		return (
		<div>
			<CanvasJSChart options = {options} 
				onRef={ref => this.chart = ref}
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}
}

export default ColumnChart;